import React from 'react';
import styled from 'styled-components';
import { Done } from '../icons';

const Wrapper = styled.div`
    padding: 12px 16px 12px 24px;
    display: flex;
    
    ${({state}) => state === 'out_of_stock' && `
        opacity: 0.7
    `}
`;

const Preview = styled.div`
    width: 52px;
    height: 52px;
    background: url(${({picture}) => picture}) no-repeat center center / 100%
`;

const Title = styled.div`
    margin-left: 12px;
    padding: 4px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: #474747;
`;

const IconWrapper = styled.div`
    width: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Selected = styled.div`
    border-radius: 24px;
    background: rgba(116, 116, 128, 0.08);
    width: 24px;
    height: 24px;
    text-align: center;
    box-shadow: 0px 0px 0px 2px rgba(244, 36, 86,1) inset;
    ${({selected}) => !selected && `
        box-shadow: none;
        path {
            stroke: rgba(116, 116, 128, 0.08);
        }
    `}
`;

const Spacing = styled.div`
    flex-grow: 1;
    margin-left: auto;
`;

const Divider = styled.div`
    height: 0px;
    left: 16px;
    right: 0px;
    bottom: 0px;
    position: relative;
    border-bottom: 0.5px solid #E2E2E3;
`;

const IconDone = styled(Done)`
    top: 2px;
    position: relative;
`;

const Description = styled.div`
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #999999;
`;

export default function({onClick, picture, title, selected, quantum, state, ...props}) {
    return (
        <React.Fragment>
        <Wrapper state={state} onClick={() => state !== 'out_of_stock' && onClick()}>
            <Preview picture={picture}/>
            <Title>
                {title}
                <Description>
                    {state !== 'out_of_stock' ? `${quantum} г.` : 'Раскупили'}
                </Description>
            </Title>
            <Spacing/>
            {state !== 'out_of_stock' && <IconWrapper>
                <Selected selected={selected}><IconDone/></Selected>
            </IconWrapper>}
        </Wrapper>
        <Divider/>
        </React.Fragment>
    )
}