import React from 'react';

import { connect } from 'react-redux';
import { getUserPhoneFromVK, updateUserDetails } from "../../store/user/actions";
import { setPage, goBack, openModal } from "../../store/router/actions";

import {
    Div,
    Panel,
    PanelHeader,
    FormLayout,
    FormItem,
    FixedLayout,
} from "@vkontakte/vkui";
import { bindActionCreators } from 'redux';
import * as gtag from '../../utils/gtag';
import { BackButton, HeaderTitle, StyledButton } from '../../components/shared';
import { checkoutUserFlow } from '../../store/router/flow';
import {Input} from "../../components/shared/form";


function Address({ id, goBack, phone, getUserPhoneFromVK, updateUserDetails, lastName, firstName, checkoutUserFlow, loading }) {

    const [values, setValues] = React.useState({
        phone: '',
        firstName,
        lastName,
    });

    React.useEffect(() => {
        setValues({
            ...values,
            phone: phone,
        })
    }, [phone])

    React.useEffect(() => {
        gtag.event('profile')
        if (!phone) {
            getUserPhoneFromVK();
        }
    }, []);

    function onChange(e) {
        e.persist();
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    }

    function submit() {
        updateUserDetails(values);
        checkoutUserFlow();
    }

    return (
        <Panel id={id}>
            <PanelHeader left={<BackButton onClick={() => goBack()}/>} className="flex" style={{justifyContent: 'center'}}>
                <HeaderTitle>
                    Профиль
                </HeaderTitle>
            </PanelHeader>

            <Div style={{ minHeight: '80vh', display: 'flex', flexDirection: 'column', paddingBottom: 60 }}>
                <div>
                    <FormLayout>
                        <FormItem top="Имя">
                            <Input
                                placeholder="80"
                                name="firstName"
                                value={values.firstName}
                                onChange={onChange}
                                medium
                            />
                        </FormItem>
                    </FormLayout>
                    <FormLayout>
                        <FormItem top="Фамилия">
                            <Input
                                placeholder="80"
                                name="lastName"
                                value={values.lastName}
                                onChange={onChange}
                                medium
                            />
                        </FormItem>
                    </FormLayout>
                    <FormLayout>
                        <FormItem top="Телефон" >
                            <Input
                                type="number"
                                value={values.phone}
                                onChange={onChange}
                                name="phone"
                                placeholder="79991234567"
                                medium
                            />
                        </FormItem>
                    </FormLayout>
                </div>
            </Div>
            <FixedLayout vertical='bottom' className={`${loading && 'Loading'}`}  style={{padding: 20,  }}>
                <StyledButton
                    disabled={!values.phone}
                    onClick={() => submit()}
                    size="xl"
                    mode="primary"
                    stretched
                    className="MainButton__Content"
                >
                    <span>Далее</span>
                </StyledButton>
            </FixedLayout>
        </Panel>
    );
}

const mapStateToProps = (state) => {
    return {
        address: state.user.address,
        firstName: state.user.firstName,
        lastName: state.user.lastName,
        phone: state.user.phone,
        loading: state.vkRouter.loading,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    setPage,
    goBack,
    getUserPhoneFromVK,
    openModal,
    updateUserDetails,
    checkoutUserFlow,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Address);
