import { createSelector } from 'reselect';


const getVisibilityFilter = state => state.kitchen.selectedCategory;
const catalogSelector = state => state.kitchen.recipes;

export const categorySelector = createSelector(
    [ getVisibilityFilter, catalogSelector ],
    (selectedCategory, catalog) => {
        return catalog.filter(t => t.category === selectedCategory)
    }
)