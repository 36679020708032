import 'core-js/es6/map';
import 'core-js/es6/set';

import React from 'react';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import '@vkontakte/vkui/dist/vkui.css';
import './css/main.css';
import VKMIniapp from './VKMIniapp';

import { initialUserFlow } from './js/store/router/flow';
import configureStore from './configureStore';
var dayjs = require('dayjs')
require('dayjs/locale/ru')
dayjs.locale('ru');
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

dayjs().format('L LT')

export const store = configureStore();
    
store.dispatch(initialUserFlow()).then(() => {
    const root = document.getElementById('root');
    // mVKMiniAppsScrollHelper(root);

    const vkMiniapp = (
        <Provider store={store} isWebView={true} webviewType="vkapps">
            <VKMIniapp/>
        </Provider>
    )

    ReactDOM.render(vkMiniapp, root);
    
    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV === "development") {
        import("./eruda").then(({ default: eruda }) => {}); //runtime download
    }
});

require('./js/utils/fix-height');