import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { goBack, closeModal, setStory, setPage, openModal, resetHistory, closeBottomSheet } from "./js/store/router/actions";
import { getActivePanel } from "./js/services/_functions";
import * as VK from './js/services/VK';

import {
    View,
    Root,
    ConfigProvider,
    ScreenSpinner
} from "@vkontakte/vkui";

import HomePanelBase from './js/panels/home/base';
import MapPanel from './js/panels/home/map';
import CartPanel from './js/panels/home/cart';
import SelectProductPanel from './js/panels/home/select';
import { saveLocation } from './js/store/user/actions';
import { Menu, Help, Profile, Stories, SelectLocationPanel, Error, SelectPayment, AddressFormPanel, Checkout, ApprovePhone } from './js/panels/home'
import { ConstructorBottomSheet, PhoneBottomSheet, PromocodeSheet } from './js/components/bottom-sheets'
import {OrdersHistory, Order} from "./js/panels/orders-history";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.lastAndroidBackAction = 0;
    }

    componentDidMount() {
        const { goBack, dispatch } = this.props;

        dispatch(VK.initApp());

        window.onpopstate = () => {
            let timeNow = +new Date();

            if (timeNow - this.lastAndroidBackAction > 500) {
                this.lastAndroidBackAction = timeNow;

                goBack();
            } else {
                window.history.pushState(null, null);
            }
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { activeView, activeStory, activePanel, scrollPosition } = this.props;

        if (
            prevProps.activeView !== activeView ||
            prevProps.activePanel !== activePanel ||
            prevProps.activeStory !== activeStory
        ) {
            let pageScrollPosition = scrollPosition[activeStory + "_" + activeView + "_" + activePanel] || 0;

            window.scroll(0, pageScrollPosition);
        }
    }

    onCloseCheckout() {
        this.props.closeModal();
        this.props.resetHistory();
        this.props.setStory('home', 'base')
    }

    render() {
        const { goBack, popouts, activeView, panelsHistory, valid, activeBottomSheet, closeBottomSheet } = this.props;

        let history = (panelsHistory[activeView] === undefined) ? [activeView] : panelsHistory[activeView];
        let popout = (popouts[activeView] === undefined) ? null : popouts[activeView];

        return (
            <>
                <ConfigProvider isWebView={true} scheme={'bright_light'}>
                    <Root id="home" activeView={activeView} popout={popout}>
                        <View
                            popout={valid === null && <ScreenSpinner />}
                            id="home"
                            activePanel={getActivePanel("home")}
                            history={history}
                            onSwipeBack={() => goBack()}
                        >
                            <Error id="error" />
                            <HomePanelBase id="base" />
                            <CartPanel id="cart" />
                            <MapPanel id="map" />
                            <SelectProductPanel id="select" />
                            <Menu id="menu" />
                            <OrdersHistory id="orders" />
                            <Order id="order" />
                            <Help id="help" />
                            <Profile id="profile" />
                            <Stories id="stories" />
                            <SelectLocationPanel id="selectLocation" />
                            <SelectPayment id="select-payment"/>
                            <AddressFormPanel id="address-form"/>
                            <Checkout id="checkout"/>
                            <ApprovePhone id="approve-phone"/>
                        </View>
                    </Root>
                </ConfigProvider>
                <ConstructorBottomSheet 
                    open={activeBottomSheet === 'constructor'} 
                    onClose={closeBottomSheet} 
                />
                <PhoneBottomSheet
                    open={activeBottomSheet === 'phone'} 
                    onClose={closeBottomSheet} 
                />
                <PromocodeSheet
                    open={activeBottomSheet === 'promocode'} 
                    onClose={closeBottomSheet} 
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activeBottomSheet: state.vkRouter.activeBottomSheet,
        activeView: state.vkRouter.activeView,
        activeStory: state.vkRouter.activeStory,
        panelsHistory: state.vkRouter.panelsHistory,
        activeModals: state.vkRouter.activeModals,
        popouts: state.vkRouter.popouts,
        scrollPosition: state.vkRouter.scrollPosition,
        price: state.cart.price,

        colorScheme: state.vkui.colorScheme,
        cartItems: state.cart.items,
        activePanel: state.vkRouter.activePanel,
        valid: state.user.valid,
    };
};


function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            setStory,
            goBack,
            closeModal,
            setPage,
            saveLocation,
            openModal,
            resetHistory,
            closeBottomSheet 
        }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
