import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#fff"
        d="M8.889 1.571a1.111 1.111 0 112.222 0V18.43a1.111 1.111 0 01-2.222 0V1.57z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M.593 10.711a1.111 1.111 0 01.853-1.822h17.108a1.111 1.111 0 010 2.222H1.446c-.33 0-.642-.146-.853-.4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
