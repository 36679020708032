import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      fill="none"
      viewBox="0 0 16 20"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M8 7.7c1.642 0 2.973-1.315 2.973-2.937 0-1.622-1.331-2.937-2.973-2.937S5.027 3.141 5.027 4.763C5.027 6.385 6.358 7.7 8 7.7zm0 1.326c2.383 0 4.315-1.908 4.315-4.263C12.315 2.41 10.383.5 8 .5 5.617.5 3.685 2.409 3.685 4.763c0 2.355 1.932 4.263 4.315 4.263zM12.245 17.122c1.044-.636 1.413-1.34 1.413-1.885 0-.546-.369-1.25-1.413-1.886C11.223 12.728 9.725 12.3 8 12.3s-3.223.428-4.245 1.051c-1.044.636-1.413 1.34-1.413 1.886 0 .546.369 1.25 1.413 1.885 1.022.623 2.52 1.052 4.245 1.052s3.223-.429 4.245-1.052zM8 19.5c3.866 0 7-1.909 7-4.263 0-2.355-3.134-4.263-7-4.263s-7 1.908-7 4.263C1 17.59 4.134 19.5 8 19.5z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M12.245 17.122c1.044-.636 1.413-1.34 1.413-1.885 0-.546-.369-1.25-1.413-1.886C11.223 12.728 9.725 12.3 8 12.3s-3.223.428-4.245 1.051c-1.044.636-1.413 1.34-1.413 1.886 0 .546.369 1.25 1.413 1.885 1.022.623 2.52 1.052 4.245 1.052s3.223-.429 4.245-1.052zM8 19.5c3.866 0 7-1.909 7-4.263 0-2.355-3.134-4.263-7-4.263s-7 1.908-7 4.263C1 17.59 4.134 19.5 8 19.5z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M8 1C5.887 1 4.185 2.69 4.185 4.763S5.887 8.526 8 8.526s3.815-1.69 3.815-3.763C11.815 2.691 10.113 1 8 1zM3.185 4.763C3.185 2.127 5.346 0 8 0c2.654 0 4.815 2.127 4.815 4.763S10.654 9.526 8 9.526c-2.654 0-4.815-2.127-4.815-4.763zM8 2.326c-1.371 0-2.473 1.097-2.473 2.437C5.527 6.103 6.63 7.2 8 7.2c1.371 0 2.473-1.097 2.473-2.437 0-1.34-1.102-2.437-2.473-2.437zM4.527 4.763c0-1.904 1.56-3.437 3.473-3.437 1.912 0 3.473 1.533 3.473 3.437S9.913 8.2 8 8.2c-1.912 0-3.473-1.533-3.473-3.437zM3.31 12.65c-1.185.722-1.81 1.652-1.81 2.588 0 .936.625 1.866 1.81 2.587C4.487 18.541 6.143 19 8 19c1.857 0 3.513-.46 4.69-1.176 1.185-.721 1.81-1.65 1.81-2.587 0-.937-.625-1.866-1.81-2.588-1.177-.716-2.833-1.175-4.69-1.175-1.857 0-3.513.459-4.69 1.175zm-.52-.854c1.357-.826 3.2-1.321 5.21-1.321s3.852.495 5.21 1.321c1.348.822 2.29 2.024 2.29 3.442 0 1.418-.942 2.62-2.29 3.441C11.852 19.505 10.01 20 8 20s-3.853-.495-5.21-1.322C1.442 17.858.5 16.655.5 15.237c0-1.418.942-2.62 2.29-3.442zM8 12.8c-1.649 0-3.053.41-3.985.978-.96.584-1.173 1.15-1.173 1.459 0 .308.214.874 1.173 1.458.932.568 2.336.979 3.985.979s3.053-.411 3.985-.979c.96-.584 1.173-1.15 1.173-1.458 0-.308-.214-.875-1.173-1.459C11.053 13.211 9.65 12.8 8 12.8zm-4.505.124C4.608 12.246 6.199 11.8 8 11.8c1.801 0 3.392.446 4.505 1.124 1.13.688 1.652 1.529 1.652 2.313 0 .784-.522 1.625-1.652 2.312-1.113.678-2.704 1.125-4.505 1.125-1.801 0-3.392-.447-4.505-1.125-1.13-.687-1.653-1.528-1.653-2.312 0-.784.523-1.625 1.653-2.313z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
