import React from 'react';

import { connect } from 'react-redux';
import { getUserPhoneFromVK, updateUserDetails, repeatSendCode, approvePhone } from "../../store/user/actions";
import { setPage, goBack, openModal } from "../../store/router/actions";

import {
    Div,
    Panel,
    PanelHeader,
    PanelHeaderBack,
    FormLayout,
    FixedLayout,
    FormStatus,
    Link,
} from "@vkontakte/vkui";
import { bindActionCreators } from 'redux';
import * as gtag from '../../utils/gtag';
import { HeaderTitle, StyledButton } from '../../components/shared';
import { checkoutUserFlow } from '../../store/router/flow';
import {FormItem, Input} from "../../components/shared/form";

function plural(forms, n) {
    let idx;
    if (n % 10 === 1 && n % 100 !== 11) {
        idx = 0; // many
    } else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
        idx = 1; // few
    } else {
        idx = 2; // one
    }
    return forms[idx] || '';
}

function ApprovePhone({ id, goBack, phone, newPhoneStart, updateUserDetails, checkoutUserFlow, loading, repeatSendCode, approvePhone }) {

    const [timeDiff, setTimeDiff] = React.useState(0);
    const [values, setValues] = React.useState({
        code: '',
    });

    React.useEffect(() => {
        console.log(`start timer`)
        const interval = setInterval(
            () => setTimeDiff(Math.round((new Date() - newPhoneStart)/1000)), 
        1000);
        return () => {
            clearInterval(interval);
        }
    }, [newPhoneStart])

    React.useEffect(() => {
        gtag.event('approve-phone')
    }, []);

    function onChange(e) {
        e.persist();
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    }

    return (
        <Panel id={id}>
            <PanelHeader left={<PanelHeaderBack onClick={() => goBack()}/>} className="flex" style={{justifyContent: 'center'}}>
                <HeaderTitle>
                    Подтверждение телефона
                </HeaderTitle>
            </PanelHeader>

            <Div style={{ minHeight: '80vh', display: 'flex', flexDirection: 'column', paddingBottom: 60 }}>
                <div>
                    <FormLayout>
                    <FormItem>
                    <FormStatus>
                        На номер {phone} был отправлен код. Введите его, чтобы подтвердить номер телефона.
                    </FormStatus>
                    </FormItem>
                        <FormItem top="Код" bottom={
                            timeDiff < 30
                            ? `Выслать код повторно можно через ${30 - timeDiff} ${plural(['секунду', 'секунды', 'секунд'], 30 - timeDiff)}`
                            : <Link onClick={() => repeatSendCode(phone)}>Повторить отправку</Link>}>
                            <Input
                                placeholder="****"
                                name="code"
                                value={values.code}
                                onChange={onChange}
                            />
                        </FormItem>
                    </FormLayout>
                </div>
            </Div>
            <FixedLayout vertical='bottom' className={`${loading && 'Loading'}`} style={{padding: 20,  }}>
                <StyledButton
                    disabled={values.code.length < 4}
                    onClick={() => approvePhone(phone, values.code)}
                    size="xl"
                    mode="primary"
                    stretched
                    className="MainButton__Content"
                >
                    <span style={{width: '100%', textAlign: 'center'}}>Далее</span>
                </StyledButton>
            </FixedLayout>
        </Panel>
    );
}

const mapStateToProps = (state) => {
    return {
        phone: state.user.newPhone,
        newPhoneStart: state.user.newPhoneStart,
        loading: state.vkRouter.loading,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    setPage,
    goBack,
    getUserPhoneFromVK,
    openModal,
    updateUserDetails,
    checkoutUserFlow,
    repeatSendCode,
    approvePhone,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ApprovePhone);
