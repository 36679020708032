import styled from "styled-components";
import { Tappable} from "@vkontakte/vkui"

export default styled(Tappable)`
    background: #F42456;
    box-shadow: 0px 0px 35px rgba(0,0,0,0.7);
    border-radius: 100px;
    width: 60px;
    height: 60px;
    cursor: pointer;
    transition: all 0.2s ease 0s;
    transform: scale(1);

    & svg {
        transform: translate(-50%, -50%);
        z-index: 9999;
        position: relative;
        top: 50%;
        left: 50%;
        transition: all 0.2s ease 0s;
    }
    
    &.scale, &:hover {
        transform: scale(1.1);
    }

    ${({counter}) => counter && `
         &:after {
            position: absolute;
            top: -5px;
            right: -5px;
            padding: 2px 7px;
            content: "${counter}";
            background: #30363E;
            border: 3px solid #30363E;
            box-sizing: border-box;
            border-radius: 1000px;
            text-align: center;
            color: #FFFFFF;
            z-index: 9999;
            font-family: Roboto;
            font-size: 10px;
        }
    `}
`;