import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

const instance = axios.create({
    baseURL: '/api/v1'
});

if (process.env.NODE_ENV === "development") {
    const mock = new MockAdapter(instance);
    mock.onPost("/api/v1/auth/vk-miniapp").reply(config => new Promise((resolve, reject) => setTimeout(() => resolve([200, require('./mock/auth.json')]), 500)));
    mock.onGet("/api/v1/auth/phone/", {params: {code: '1111', phone: '79111234568'}}).reply(config => new Promise((resolve, reject) => setTimeout(() => resolve([201, require('./mock/auth.json')]), 500)));
    mock.onGet("/api/v1/auth/phone/", {params: {phone: '79111234568'}}).reply(config => new Promise((resolve, reject) => setTimeout(() => resolve([201, null]), 500)));
    mock.onGet("/api/v1/kitchen").reply(config => new Promise((resolve, reject) => setTimeout(() => resolve([200, require('./mock/kentuki')]), 500)));
    mock.onPost("/api/v1/orders").reply(config => new Promise((resolve, reject) => setTimeout(() => resolve([200, require('./mock/create-order.json')]), 5000)));
    mock.onGet("/api/v1/geo/suggests").reply(config => new Promise((resolve, reject) => setTimeout(() => resolve([200, require('./mock/geo-suggests.json')]), 50)));
    mock.onGet("/api/v1/geo/reverse").reply(config => new Promise((resolve, reject) => setTimeout(() => resolve([200, require('./mock/geo-reverse.json')]), 50)));
    mock.onGet("/api/v1/orders").reply(config => new Promise((resolve, reject) => setTimeout(() => resolve([200, require('./mock/orders.json')]), 50)));
    mock.onPut(/\/api\/v1\/cart\/.+/).reply(config => new Promise((resolve, reject) => setTimeout(() => resolve([200, require('./mock/cart.json')]), 100)));
    mock.onGet(/\/api\/v1\/cart\/.+/).reply(config => new Promise((resolve, reject) => setTimeout(() => resolve([200, require('./mock/cart.json')]), 100)));
    mock.onGet(/\/api\/v1\/orders\/.+/).reply(config => new Promise((resolve, reject) => setTimeout(() => resolve([200, require('./mock/order.json')]), 100)));

};

export default instance;