export const SET_PAGE = 'SET_PAGE';
export const SET_PAGE_CLEAN_HISTORY = 'SET_PAGE_CLEAN_HISTORY';
export const SET_STORY = 'SET_STORY';
export const GO_BACK = 'GO_BACK';
export const OPEN_POPOUT = 'OPEN_POPOUT';
export const CLOSE_POPOUT = 'CLOSE_POPOUT';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const RESET_HISTORY = 'RESET_HISTORY';
export const OPEN_BOTTOM_SHEET = 'OPEN_BOTTOM_SHEET';
export const CLOSE_BOTTOM_SHEET = 'CLOSE_BOTTOM_SHEET';
export const LOADING_START = 'LOADING_START';
export const LOADING_FINISH = 'LOADING_FINISH';
export const SET_FLOW = 'SET_FLOW';