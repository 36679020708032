import React from 'react';

import { connect } from 'react-redux';
import { goBack, openModal } from "../../store/router/actions";
import {
  Panel,
  PanelHeader,
  Group,
  Div,
  FormLayout,
  FixedLayout,
} from "@vkontakte/vkui";
import * as gtag from '../../utils/gtag';
import { BackButton, HeaderTitle, StyledButton } from '../../components/shared';
import { updateAddressDetails } from '../../store/user/actions';
import { checkoutUserFlow } from '../../store/router/flow';
import { upsertCart } from '../../store/cart/actions';
import {FormItem, Input, Textarea} from "../../components/shared/form";

function SetAddressFormPanel({ id, goBack, currentAddress, updateAddressDetails, checkoutUserFlow, loading, upsertCart }) {

  const [values, setValues] = React.useState({
    ...currentAddress
  });

  React.useEffect(() => {
    gtag.event('set-address');
  }, []);


  function onChange(e) {
    e.persist();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }

  async function  submit() {
    await updateAddressDetails(values);
    await upsertCart();
    checkoutUserFlow();
  }

  return (
    <Panel id={id}>
      <PanelHeader left={<BackButton onClick={() => goBack()} />} className="flex" style={{ justifyContent: 'center' }}>
        <HeaderTitle>
          Адрес доставки
        </HeaderTitle>
      </PanelHeader>
      <Div>

        <Group>
          <FormLayout>
            <FormItem top="Улица">
              <Input
                  name="street"
                  value={values.name}
                  onChange={onChange}
                  placeholder=""
                  disabled
                  medium
                  notTransparent
              />
            </FormItem>
            <FormItem top="Квартира">
              <Input
                top="Квартира"
                placeholder="80"
                name="flat"
                value={values.flat}
                onChange={onChange}
                medium
              />
            </FormItem>
            <FormItem top="Этаж">
              <Input
                placeholder="2"
                name="floor"
                value={values.floor}
                onChange={onChange}
                medium
              />
            </FormItem>
            <FormItem top="Подъезд">
              <Input
                placeholder="3"
                name="entrance"
                value={values.entrance}
                onChange={onChange}
                medium
              />
            </FormItem>
          </FormLayout>
          <FormLayout>
            <FormItem top="Комментарий">
              <Textarea
                name="comment"
                value={values.comment}
                onChange={onChange}
                medium
              />
            </FormItem>
          </FormLayout>
        </Group>
      </Div>
      <FixedLayout vertical='bottom' className={loading && 'Loading'}  style={{padding: 20,  }}>
        <StyledButton
          disabled={!values.flat}
          onClick={() => submit()}
          size="xl"
          mode="primary"
          stretched
          className="MainButton__Content"
        >
          <span style={{width: '100%', textAlign: 'center'}}>Везите сюда</span>
        </StyledButton>
      </FixedLayout>
    </Panel>
  );
}

const mapStateToProps = state => ({
  currentAddress: state.user.address,
  loading: state.vkRouter.loading,
})

const mapDispatchToProps = {
  goBack,
  openModal,
  updateAddressDetails,
  checkoutUserFlow,
  upsertCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetAddressFormPanel);