import React from 'react';
import Sheet from 'react-modal-sheet';
import { connect } from 'react-redux';
import {setRecipe} from '../../store/kitchen/actions';
import { addItemToCart } from '../../store/cart/actions';
import { categorySelector } from '../../store/kitchen/selectors';
import * as gtag from '../../utils/gtag';

import {
    Card,
    Div,
    Button,
    Gallery,
    Counter,
    Select,
    FixedLayout,
    CustomSelectOption,
} from "@vkontakte/vkui";
import styled from 'styled-components';
import { Plus as PlusIcon, Minus as MinusIcon } from '../icons';
import { StyledButton, Nutritional, Ingredient as IngredientOption, Divider, ButtonHelper } from '../shared';
import { UNIT_GRAM } from '../../models/unit';


const Minus = styled(Button)`
    width: 36px;
    height: 36px;
    background: #F42456;
    border-radius: 100px;

    transition: all 0.2s ease 0s;
    transform: scale(1);

    &.Tappable--active, &:hover {
        transform: scale(1.1);
    }
`;

const Plus = styled(Button)`
    height: 36px;
    background: #F42456;
    border-radius: 100px;
    color: #fff;
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    width: ${({current}) => current > 0 ? '36px' : 'auto'};
    padding: ${({current}) => current > 0 ? '2px 4px' : '0 26px'};

    transition: all 0.2s ease 0s;
    transform: scale(1);

    &.Tappable--active, &:hover {
        transform: scale(1.1);
    }
`;

const QTY = styled(Button)`
    padding: 0;
    width: 44px;
    height: 36px;
    box-sizing: border-box;
    border-radius: 0;
    min-width: 65px;
    background: rgba(255, 255, 255, 0);
    
    .Button__content {
        padding: 6px 0 6px;
        font-size: 17px;
    }
`;

const Description = styled.div`
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #999999;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
`;

const Title = styled.div`
    padding: 4px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: #474747;
`;

const SubTitle = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.078px;
    text-transform: uppercase;
    color: #8A8A8D;
    padding: 24px 0 16px 20px;
`;

// eslint-disable-next-line react/prop-types
function Ingredient({ picture, title, current, max, onClick, onIncreaseClick,
    onReduceClick, choices, onSelectChoice, weight, maxWeight, currentWeight,
    showWeight, count, maxCount }) {

    return (
        <>
        <div className="Card__Ingredient">
            <Card
                onClick={() => onClick && onClick()}
                className="ingredient"
                style={{
                    background: picture && `url(${picture}) no-repeat center center / 80%`,
                }}>
            </Card>
            <div className="Card__Content">
                <Title>
                    {title}
                </Title>

                {!showWeight && <Description>{weight} г</Description>}

                {choices && current > 0 && <div style={{ display: 'flex', marginTop: '12px', justifyContent: 'flex-start', width: '100%' }}>
                    <Select
                        placeholder={choices.name}
                        style={{ width: '100%' }}
                        value={choices.selected}
                        options={choices.items.map(option => ({ label: option.name, value: option.id }))}
                        renderOption={({ option, ...restProps }) => (
                            <CustomSelectOption {...restProps}>{option.name}</CustomSelectOption>
                        )}
                        onChange={onSelectChoice}>
                        {choices.items.map((choice, key) => <option value={choice.id} key={key}>{choice.name}</option>)}
                    </Select>
                </div>}

                <div style={{ display: 'flex', marginTop: '12px', justifyContent: 'flex-start', width: '100%' }}>
                    {max === 1 && <StyledButton
                        onClick={current > 0 ? onReduceClick : onIncreaseClick}
                        className="qty"
                        disabled={maxCount !== 1 && maxCount && count >= maxCount && current === 0}
                        mode={current > 0 ? 'primary' : 'secondary'}>&#10003;</StyledButton>}
                    {max !== 1 && current > 0 && 
                        <Minus
                            className="qty"
                            onClick={onReduceClick}
                            style={{padding: '2px 4px'}} 
                        > <MinusIcon /> </Minus>}
                    {max !== 1 && current > 0 && <QTY
                        mode="tertiary"> {showWeight ? `${weight * current} г` : `${current} шт.`}</QTY>}
                    {max !== 1 && <Plus
                        current={current}
                        className="qty"
                        onClick={onIncreaseClick}
                        disabled={
                            (current >= max) ||
                            (maxWeight && currentWeight && ((currentWeight + weight) >= maxWeight)) ||
                            (maxCount && count >= maxCount)}
                        > {current > 0 ? <PlusIcon/> : 'Добавить'} </Plus>}
                </div>
            </div>
            
        </div>
        <Divider/>
        </>
    )
}

function Constructor({ open, selectedRecipe, onClose, products, addItemToCart, setRecipe }) {

    if (!products.length || !selectedRecipe) {
        return <Sheet isOpen={open} onClose={onClose} className="bottom-sheet">
            <Sheet.Container>
                <Sheet.Header />
                <Sheet.Content>
                </Sheet.Content>
            </Sheet.Container>
        </Sheet>
    }

    const [slideIndex, setSlideIndex] = React.useState(products.findIndex(p => p.id === selectedRecipe.id));
    const [product, setSelected] = React.useState(processing(selectedRecipe))
    const [selectedCategory, setSelectedCategory] = React.useState(selectedRecipe.components.length ? selectedRecipe.components[0].id : null)
    
    React.useEffect(() => {
        if (!selectedRecipe || !products.length) {
            return;
        }
        setSlideIndex(products.findIndex(p => p.id === selectedRecipe.id));
        setSelected(processing(selectedRecipe));
        setSelectedCategory(selectedRecipe.components.length ? selectedRecipe.components[0].id : null);
        gtag.event('view_item', {
            "items": [
                {
                    "id": selectedRecipe.id,
                    "name": selectedRecipe.baseName,
                    "quantity": 1,
                    "price": selectedRecipe.price,
                    "value": selectedRecipe.price,
                }
            ]
        });
    }, [selectedRecipe])

    async function updateEvent(ingredient, qty) {
        gtag.event('change_ingredient', {
            "id": ingredient.id,
            "product_id": product.id,
            "product_name": product.baseName,
            "name": ingredient.name,
            "quantity": qty,
            "price": ingredient.price,
            "value": ingredient.price
        });
    }

    function onUpdateItem(id, qty) {
        const components = [];
        for (const i in product.components) {
            const ingredient = { ...product.components[i] }
            const ingredients = [];
            const onlyOne = product.components[i].max === 1 && product.components[i].ingredients.findIndex(jj => jj.id === id) > -1;
            for (const j in product.components[i].ingredients) {
                const item = {
                    ...product.components[i].ingredients[j],
                    qty: onlyOne ? 0 : product.components[i].ingredients[j].qty
                };
                if (item.id === id) {
                    item.qty += qty;
                    updateEvent(item, qty).then();
                }
                ingredients.push(item)
            }
            components.push({
                ...ingredient,
                ingredients
            })
        }
        setSelected(processing({ ...product, components }))
    }

    function onSelectChoice(id, choiceId) {
        const components = [];
        for (const i in product.components) {
            const ingredient = { ...product.components[i] }
            const items = [];
            for (const j in product.components[i].ingredients) {
                const item = { ...product.components[i].ingredients[j] };
                if (item.id === id) {
                    item.choices = {
                        ...item.choices,
                        selected: choiceId
                    }
                }
                items.push(item)
            }
            components.push({
                ...ingredient,
                items
            })
        }
        setSelected(processing({ ...product, components }))
    }

    function makeTitle(title, titleWith) {
        if (titleWith.length > 1) {
            const postfix = titleWith.splice(titleWith.length - 1, 1)
            return `${title} c ${titleWith.join(', ')} и ${postfix}`
        }
        if (titleWith.length === 1) {
            return `${title} c ${titleWith[0]}`
        }
        return title;
    }

    function processing(selected) {
        const components = [];
        let price = selected.basePrice || 0;
        const specification = {
            weight: 0, // 60 грамм
            proteins: 0,
            fats: 0,
            carbohydrates: 0,
            ccal: 0,
        }
        let title = selected.baseName || selected.name;
        let titleWith = [];
        selected.components.forEach(ingredient => {
            let count = 0;
            let maxQty = 0;
            let image = ingredient.ingredients[0].image;
            let hasIngredient = false;
            let weight = 0;
            ingredient.ingredients.forEach(item => {
                count += item.qty;
                image = item.qty > maxQty ? item.image : image;

                if (item.qty > 0 && item.title && item.title.main) {
                    title = item.qty > maxQty ? item.title.main : title;
                }
                maxQty = item.qty > maxQty ? item.qty : maxQty;
                if (item.qty <= 0) {
                    return
                }

                hasIngredient = true;

                if (item.title && !item.title.multiple && item.title.with) {
                    titleWith.push(item.title.with)
                }

                price += item.price * item.qty
                specification.weight += Math.round(item.quantum * item.qty)
                specification.proteins += Math.round(item.specification.proteins * item.quantum / 100 * item.qty)
                specification.fats += Math.round(item.specification.fats * item.quantum / 100 * item.qty)
                specification.carbohydrates += Math.round(item.specification.carbohydrates * item.quantum / 100 * item.qty)
                specification.ccal += Math.round(item.specification.ccal * item.quantum / 100 * item.qty)
                weight += item.specification.quantum * item.qty
            });
            let error = false;
            if (!hasIngredient && ingredient.required) {
                error = true;
            }
            components.push({
                ...ingredient,
                error: error,
                image,
                count,
                weight,
            })
        })
        return {
            ...selected,
            price,
            components,
            specification,
            baseName: selected.baseName || selected.name,
            name: makeTitle(title, titleWith),
            enable: components.findIndex(ingredient => ingredient.error) === -1,
        }
    }


    function changeBaseRecipe(i) {
        if (i === false) {
            return
        }
        setSlideIndex(i); 
        setSelected(processing({ ...products[i] }));
        setSelectedCategory(products[i].components.length ? products[i].components[0].id : null)
        setRecipe(products[i]);
    }

    const submit = (e) => {
        addItemToCart({
            ...product,
            qty: 1,
        });
        onClose();
    }

    return (
        <Sheet isOpen={open} onClose={onClose} disableDrag={false} className="bottom-sheet">
            <Sheet.Container>
                <Sheet.Content>
                    <Gallery
                        slideIndex={slideIndex}
                        onChange={changeBaseRecipe}
                        style={{ height: 320, zIndex: 777 }}
                        bullets={products.length > 1 && "dark"}
                    >
                        {products.map((p, key) =>
                            <div
                                key={key}
                                style={{
                                    height: 320,
                                    witdh: '100%',
                                    backgroundBlendMode: 'normal, multiply',
                                    background: `rgba(0, 0, 0, 0) url(${p.image}) no-repeat scroll center center / ${(p && p.images && p.images.scale) || 'contain'}`,
                                }}
                            />
                        )}
                    </Gallery>
                    <Div className="ModalPage__Content" style={{ 'overflow': 'auto', borderRadius: '24px 24px 0 0', marginTop: '-24px', background: '#fff', zIndex: 9999, position: 'absolute', padding: '20px 0 0 0', width: '100%' }}>

                        <Title style={{ margin: '0 22px 6px 22px' }}>
                            {product.name}
                        </Title>

                        <Div
                            className="flex-container"
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                flexWrap: 'wrap',
                            }}>
                            {product.components.map((ingredient, key) =>
                                <Card
                                    className="flex-item"
                                    key={key}
                                    onClick={() => setSelectedCategory(ingredient.id)}
                                    style={{
                                        flex: product.components.length > 4 ? 'flex: 0 0 20%' : 'flex: 0 0 25%',
                                        padding: product.components.length > 4 ? '8%' : '10%',
                                        boxSizing: 'border-box',
                                        borderRadius: 44,
                                        borderColor: '#F42456',
                                        borderWidth: 2,
                                        opacity: ingredient.count > 0 ? 1 : .5,
                                        transition: 'all 0.2s ease 0s',
                                        fontFamily: 'Roboto',
                                        boxShadow: selectedCategory === ingredient.id && '#F42456 0px 0px 0px  2px',
                                        // boxShadow: selectedCategory === ingredient.id ? 'rgb(149, 149, 149) 0px 1px 1px inset' : undefined,
                                        background: ingredient.count > 0
                                            ? `rgb(255, 255, 255) url(${ingredient.image}) no-repeat center center / 80%`
                                            : `rgba(255, 255, 255) url(${ingredient.image}) no-repeat center center / 80%`,
                                    }}>
                                    {ingredient.error &&
                                        <Counter style={{
                                            position: 'absolute',
                                            background: 'rgb(255, 111, 111)',
                                            right: product.components.length > 4 ? '-2.1rem' : '-2.4rem',
                                            top: product.components.length > 4 ? '-2.1rem' : '-2.4rem'
                                        }}>
                                            !
                                    </Counter>
                                    }
                                    {ingredient.count > 1 &&
                                        <Counter style={{
                                            position: 'absolute',
                                            right: product.components.length > 4 ? '-2.1rem' : '-2.4rem',
                                            top: product.components.length > 4 ? '-2.1rem' : '-2.4rem'
                                        }}>
                                            {ingredient.count}
                                        </Counter>
                                    }
                                </Card>
                            )}

                        </Div>
                        {product.components.map((ingredient, ingredientIndex) => selectedCategory === ingredient.id && <React.Fragment key={ingredientIndex}>
                            <Title style={{ margin: '22px 22px 6px 22px' }}>
                                {ingredient.name}
                            </Title>
                            <div className='content'>
                                {ingredient.ingredients.map((item, productIndex) =>
                                    <React.Fragment key={productIndex}>
                                        {item.max <= 1 
                                        ? <IngredientOption
                                            state={item.state}
                                            key={productIndex}
                                            picture={item.image}
                                            title={item.name}
                                            selected={item.qty > 0}
                                            quantum={item.quantum}
                                            onClick={() => onUpdateItem(item.id, item.qty > 0 ? -1 : 1)}
                                        /> 
                                        : <Ingredient
                                            choices={item.choices}
                                            picture={item.image}
                                            title={item.name}
                                            current={item.qty}
                                            onReduceClick={() => onUpdateItem(item.id, -1)}
                                            onIncreaseClick={() => onUpdateItem(item.id, +1)}
                                            onSelectChoice={(e) => onSelectChoice(item.id, e.target.value)}
                                            max={item.max}
                                            count={ingredient.count}
                                            maxCount={ingredient.max}
                                            weight={item.quantum}
                                            currentWeight={item.quantum * item.qty}
                                            maxWeight={ingredient.maxWeight}
                                            showWeight={item.unit === UNIT_GRAM}
                                        />}
                                        
                                    </React.Fragment>)}
                            </div>
                        </React.Fragment>
                        )}
                        <SubTitle>Пищевая и энергетическая ценность на 100 г.</SubTitle>
                        <Nutritional
                            proteins={Math.round(product.specification.proteins * 100 / product.specification.weight)}
                            fats={Math.round(product.specification.fats * 100 / product.specification.weight)}
                            carbohydrates={Math.round(product.specification.carbohydrates * 100 / product.specification.weight)}
                            ccal={Math.round(product.specification.ccal * 100 / product.specification.weight)}
                        />
                    </Div>


                </Sheet.Content>
                {product.enable && <FixedLayout vertical='bottom' style={{ display: !open && 'none', padding: 20, zIndex: 99999}}>
                    <StyledButton
                        id="addButton"
                        onClick={submit}
                        size="xl"
                        stretched
                        disabled={!product.enable}
                        mode="primary"
                    >
                        <span>
                            В корзину
                        </span>
                        <ButtonHelper>{product.price} ₽</ButtonHelper>
                    </StyledButton>
                </FixedLayout>}
            </Sheet.Container>
            <Sheet.Backdrop />
        </Sheet>
    )
}

const mapStateToProps = state => ({
    products: categorySelector(state),
    selectedRecipe: state.kitchen.selectedRecipe,
})

const mapDispatchToProps = {
    addItemToCart,
    setRecipe,
};

export default connect(mapStateToProps, mapDispatchToProps)(Constructor);