import { HorizontalScroll } from '@vkontakte/vkui';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    background: ${({background}) => background};
    border-radius: 20px;
    padding: 20px 0 20px 20px;
    margin: 15px 0;
    font-family: 'Roboto';
`;

const Title = styled.div`
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 130%;
    color: #30363E;
`;

const Subtitle = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #30363E;
    margin: 5px 0;
`;

export const StyledHorizontalScroll = styled(HorizontalScroll)`
    margin: 0 0 0 -20px;
`

// eslint-disable-next-line react/prop-types,react/display-name
export default ({title, subtitle, children, background}) => {
    return (
        <Wrapper background={background}>
            {title && <Title>{title}</Title>}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
            <StyledHorizontalScroll>
                
                {children}
            </StyledHorizontalScroll>
        </Wrapper>
    )
}