import { Tappable } from '@vkontakte/vkui';
import styled from 'styled-components';

const Wrapper = styled(Tappable)`
    width: 40px;
    height: 36px;
    background: #F42456;
    border-radius: 100px;
    margin-left: 10px;
    transition: all 0.2s ease 0s;
    transform: scale(1);

    &.scale, &:hover {
        transform: scale(1.1);
    }

    & svg {
        transform: translate(-50%, -50%);
        position: relative;
        top: 50%;
        left: 50%;
    }
`

export default Wrapper;