import styled from "styled-components";

export default styled.div`
    padding: 15px 3px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;

    /* identical to box height, or 165% */
    text-align: center;
    letter-spacing: 0.35px;

    color: #474747;
`;