import { Back } from '../icons';
import React from 'react';
import LeftButton from './LeftButton';


// eslint-disable-next-line react/display-name
export default function (props) {
    return (
        <LeftButton activeMode='scale' {...props}>
            <Back/>
        </LeftButton>
    )
}