import React from "react";

function Icon({fill='#fff'}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M1.324.147a1.389 1.389 0 011.863.621l1.977 3.955h14.102c2.01 0 3.66 0 4.963.175 1.364.183 2.555.581 3.507 1.533s1.35 2.143 1.534 3.507c.175 1.303.175 2.952.175 4.963v1.731l-17.67 7.124L3.066 6.738.703 2.01a1.389 1.389 0 01.62-1.863zM6.577 7.5l6.514 12.73 13.576-5.473c0-2.003-.009-3.394-.15-4.448-.142-1.057-.395-1.564-.745-1.914-.35-.35-.857-.602-1.913-.744-1.097-.148-2.558-.15-4.692-.15H6.577zM6.25 24.445a1.389 1.389 0 100 2.778 1.389 1.389 0 000-2.778zm-4.166 1.389a4.167 4.167 0 118.333 0 4.167 4.167 0 01-8.333 0zM22.917 24.445a1.389 1.389 0 100 2.778 1.389 1.389 0 000-2.778zm-4.167 1.389a4.167 4.167 0 118.334 0 4.167 4.167 0 01-8.334 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
