import styled from "styled-components";
import {Button} from "@vkontakte/vkui";

export default styled(Button)`
    width: 36px;
    height: 36px;
    background: #F42456;
    border-radius: 100px;

    transition: all 0.2s ease 0s;
    transform: scale(1);

    &.Tappable--active, &:hover {
        transform: scale(1.1);
    }
`;