export const SET_CART_CANDIDATE = 'SET_CART_CANDIDATE';
export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';
export const ADD_INGREDIENT_TO_CART_CANDIDATE = 'ADD_INGREDIENT_TO_CART_CANDIDATE';
export const REMOVE_INGREDIENT_FROM_CART_CANDIDATE = 'REMOVE_INGREDIENT_FROM_CART_CANDIDATE'
export const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART';
export const CHECKOUT = 'CHECKOUT';
export const UPDATE_PAYMENT_TYPE = 'UPDATE_PAYMENT_TYPE';
export const UPDATE_CART_ID = 'UPDATE_CART_ID';
export const PAYMENT_DONE = 'PAYMENT_DONE';

export const UPDATE_PRICES = 'UPDATE_PRICES';

export const SET_PROMO = 'SET_PROMO';