import { Forward } from '../icons';
import React from 'react';
import RightButton from "./RightButton";


// eslint-disable-next-line react/display-name
export default function (props) {
    return (
        <RightButton activeMode='scale' {...props}>
            <Forward/>
        </RightButton>
    )
}