import styled from "styled-components";
import {SimpleCell} from "@vkontakte/vkui";

export default styled(SimpleCell)`
    min-height: 28px;

    .SimpleCell__content {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 28px;
        letter-spacing: -0.24px;
        color: #999999;

        .SimpleCell__after {
            color: #999999 !important;
        }
        strong {
            color: #474747 !important;
        }
    }    
    .SimpleCell__main {
        padding-top: 0;
        padding-bottom: 0;
    }
`;