import React from 'react';
import {connect} from 'react-redux';
import {setPage, goBack} from "../../store/router/actions";
import {addItemToCart, removeItemFromCart} from '../../store/cart/actions';
import {categorySelector} from '../../store/kitchen/selectors';
import styled from 'styled-components';
import * as gtag from '../../utils/gtag';
import { Plus as PlusIcon, Minus as MinusIcon, Cart } from '../../components/icons';

import {
    Div,
    Panel,
    Button,
    PanelHeader,
    Card,
    FixedLayout,
} from "@vkontakte/vkui";
import { bindActionCreators } from 'redux';
import { BackButton, IconButton } from '../../components/shared';

const ButtonGroup = styled.div`
    display: flex;
`;

const Minus = styled(Button)`
    width: 36px;
    height: 36px;
    background: #F42456;
    border-radius: 100px;

    transition: all 0.2s ease 0s;
    transform: scale(1);

    &.Tappable--active, &:hover {
        transform: scale(1.1);
    }
`;

const Plus = styled(Button)`
    width: 36px;
    background: #F42456;
    border-radius: 8px 0 0 8px;
    border-radius: 100px;
    color: #333;
    border-radius: 100px;
    transform: ${({current}) => current > 0 && `rotate(180deg)`};

    line-height: 28px;
    width: ${({current}) => current > 0 ? '36px' : 'auto'};
    padding: ${({current}) => current > 0 ? '0' : '0 26px'};
    min-height: 0;

    & .Button__content {
        color: #fff;
        font-weight: 500;
        font-size: 17px;
    }

    transition: all 0.2s ease 0s;
    transform: scale(1);

    &.Tappable--active, &:hover {
        transform: scale(1.1);
    }
`;

const QTY = styled(Button)`
    width: 44px;
    height: 36px;
    background: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    border-radius: 0;
    
    .Button__content {
        padding: 6px 0 6px;
        font-size: 17px;
    }
`;

const Price = styled.div`
    padding: 4px 3px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;

    text-align: center;
    letter-spacing: 0.35px;

    color: #474747;
`;

const Divider = styled.div`
    height: 0px;
    left: 16px;
    right: 0px;
    bottom: 0px;
    position: relative;
    border-bottom: 0.5px solid #E2E2E3;
`;

const Title = styled.div`
    padding: 4px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: #474747;
`;

function Item({picture, title, current, max, onClick, onIncreaseClick, onReduceClick, price}) {
    return (
        <div className="Card__Ingredient" style={{paddingBottom: 12}}>
               
            <Card
                onClick={() => onClick && onClick()}
                className="ingredient" 
                style={{
                    width: 72,
                    height: 72,
                    background: `url(${picture}) no-repeat center center / 100%`,
            }}/>
            <div className="Card__Content">
                <Title>
                    {title}
                </Title>
                <div style={{display: 'flex', justifyContent: 'space-between', padding: '8px 0'}}>
                    <ButtonGroup style={{justifyContent: 'flex-start'}}>
                    {current > 0 && <Minus 
                                        onClick={onReduceClick}
                                        className="qty" 
                                        style={{padding: '2px 4px'}} 
                                        mode="secondary"> <MinusIcon/> </Minus>}
                    {current > 0 && <QTY
                                        className="qty" 
                                        style={{padding: '2px 3px'}} 
                                        mode="tertiary"> {current} </QTY>}
                    {max > current && <Plus 
                                        current={current}
                                        onClick={onIncreaseClick}
                                        mode="secondary"> {current > 0 ? <PlusIcon/> : 'Добавить'} </Plus>}
                    </ButtonGroup>
                    <Price>
                        {price}&nbsp;₽
                    </Price>
                </div>
            </div>
        </div>
    )
}

function SelectProduct({id, filter, addItemToCart, removeItemFromCart, address, goBack, setPage, products, cartItems}) {

    React.useEffect(() => {
        gtag.event('view-select')
    }, [])

    const currentQty = cartItems.reduce(((result, item) => {
        result[item.id] = item.qty
        return result
    }), {})
    return (
        <Panel id={id}>
            <PanelHeader left={<BackButton onClick={() => goBack()}/>} className="flex">
                <h1>Напитки</h1>
            </PanelHeader>

            <Div style={{padding: 0, paddingBottom: cartItems.length > 0 ? 48 : 0}}>
                <div className='content'>
                    {products.map((item, key) => <React.Fragment key={key}>
                        <Item 
                            title={item.name}
                            picture={item.image} 
                            price={item.price} 
                            onReduceClick={() => removeItemFromCart({...item})}
                            current={currentQty[item.id] || 0}
                            max={99}
                            onIncreaseClick={() => addItemToCart({...item, qty: 1})}/>
                            {products.length-1 !== key && <Divider/>}
                        </React.Fragment>
                    )}
                </div>
            </Div>
            {cartItems.length > 0 && <FixedLayout vertical='bottom' className="FixedIconButton"  style={{padding: 20,  }}>
                <IconButton
                    onClick={() => setPage('home', 'cart')}
                    size="xl" 
                    mode="primary"
                    activeMode='scale'
                    counter={cartItems.length}
                >
                        <Cart/>
                </IconButton>
                
            </FixedLayout>}
        </Panel>
    );
}

const mapStateToProps = state => ({
        activeTab: state.vkui.activeTab,
        address: state.user.address,
        cartItems: state.cart.items,
        filter: state.kitchen.selectedCategory,

        products: categorySelector(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setPage,
    addItemToCart,
    removeItemFromCart,
    goBack,
    
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectProduct);
