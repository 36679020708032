import { getOrder, getOrders } from "../../http/orders";
import * as types from './types';

const statusToTime = {
    'created': 15000,
    'paid': 3000,
    'start_cooking': 60000,
    'handover': 60000,
}

const isFinal = (o) => o.status === 'done' || o.status === 'canceled'

async function tryOrder(orderID, dispatch, getState) {
    try {
        const {orders: {activeOrder}} = getState();
        const resp = await getOrder(orderID);
        if (!activeOrder || resp.status !== activeOrder.status) {
            dispatch({
                type: types.SET_ACTIVE_ORDER,
                payload: resp,
            })
        }
        if (!isFinal(resp)) {
            const time = statusToTime[resp.status];
            if (!time) {
                return;
            }
            setTimeout(() => tryOrder(orderID, dispatch, getState).then(), 10000)
        }
    } catch (err) {
        console.log(err)
        setTimeout(() => tryOrder(orderID, dispatch, getState).then(), 10000)
    }
}

export const pollOrder = (orderID) => (dispatch, getState) => {
    tryOrder(orderID, dispatch, getState).then()
}

export const initialOrdersHistory = () => async (dispatch, getState) => {
    const {items, total} = await getOrders({limit: 100, offset: 0});
    dispatch({
        type: types.APPEND_ORDERS,
        payload: {items, total}
    })
    for (const o of items) {
        if (!isFinal(o)) {
            dispatch({
                type: types.SET_ACTIVE_ORDER,
                payload: o,
            })
            tryOrder(o.id, dispatch, getState).then()
            break
        }
    }
}

export const selectOrder = (order) => dispatch => {
    dispatch({
        type: types.SELECT_ORDER,
        payload: order,
    })
}