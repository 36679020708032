import * as types from './types';

const initialState = {
    id: null,
    categories: [],
    recipes: [],
    selectedCategory: 'burger',
    selectedRecipe: null,
    loading: false,
    error: null,
    priceLevels: [],
    status: '',
    paymentTypes: [{
        id: 0,
        name: 'Картой онлайн',
        disabled: false,
    }, {
        id: 1,
        name: 'Картой курьеру',
        disabled: true,
    }, {
        id: 2,
        name: 'Наличными',
        disabled: false,
    }],
}

export default function (state=initialState, {type, payload}) {
    switch (type) {
        case types.SET_RECIPE:
            return {
                ...state,
                recipe: payload,
            }
        case types.SET_SELECTED_CATEGORY:
            const {selectedRecipe, category} = payload;
            return {
                ...state,
                selectedRecipe: selectedRecipe,
                selectedCategory: category,
            }
        case types.RESOLVE_KITCHEN_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.RESOLVE_KITCHEN_SUCCESS:
            const {recipes, categories, id, priceLevels, paymentTypes, status} = payload;
            return {
                ...state,
                id,
                recipes,
                categories,
                loading: false,
                priceLevels: priceLevels,
                status,
                paymentTypes: paymentTypes ? paymentTypes : state.paymentTypes
            }
        case types.RESOLVE_KITCHEN_ERROR:
            return {
                ...state,
                categories: [],
                recipes: [],
                error: payload,
                loading: false,
            };
        default:
            return state;
    }
}