import styled from 'styled-components';
import { Button } from '@vkontakte/vkui'

const StyledButton = styled(Button)`
    background: ${({mode}) => mode === `primary` && `#F42456`};
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    min-height: 50px;
    
    & .Button__content {
        align-items: center;
        width: 100%;
        font-size: 17px;
        justify-content: space-between;
        padding: 8px 10px 8px 20px;
    }

    transition: all 0.2s ease 0s;
    transform: scale(1);

    &.Tappable--active, &:hover {
        transform: scale(1.1);
    }
`;

export const Helper = styled.div`
    padding: 4px 10px;
    background: rgba(255, 255, 255, 0.25);
    border-radius: 10px;
    font-size: 14px;
`;

export default StyledButton