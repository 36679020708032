import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      fill="none"
      viewBox="0 0 18 16"
    >
      <path
        fill="#fff"
        d="M7.293.293a1 1 0 011.414 1.414L3.414 7H17a1 1 0 01.993.883L18 8a1 1 0 01-1 1H3.414l5.293 5.293a1 1 0 01.083 1.32l-.083.094a1 1 0 01-1.414 0l-7-7-.073-.082a1.096 1.096 0 01-.126-.202.961.961 0 01-.083-.275A.997.997 0 010 8l.004.09A1.006 1.006 0 010 8.02V8a1.02 1.02 0 01.125-.484.885.885 0 01.071-.111.999.999 0 01.097-.112l-.08.09c.025-.031.051-.062.08-.09l7-7z"
      ></path>
    </svg>
  );
}

export default Icon;
