import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-around;
    color: #8A8A8D;
    font-family: Comfortaa;
    padding: 8px 16px 20px 8px;

    & strong {
        color: #474747;
    }

    & div {
        width: 25%;
        border-right: 1px solid #E2E2E3;
        text-align: center;
        font-size: 14px;
        &:last-child {
            border-right: none;
        }
    }
`;

export default function ({ proteins, fats, carbohydrates, ccal }) {
    return (
        <Wrapper>
            {proteins > 0 && (
                <div>
                    <strong>
                        Б:&nbsp;
                        </strong>
                    {proteins}
                </div>
            )}
            {fats > 0&& (
                <div>
                    <strong>
                        Ж:&nbsp;
                        </strong>
                    {fats}
                </div>
            )}
            {carbohydrates > 0 && (
                <div>
                    <strong>
                        У:&nbsp;
                        </strong>
                    {carbohydrates}
                </div>
            )}
            {ccal > 0 && (
                <div>
                    <strong>
                        {ccal} ккал
                        </strong>
                </div>
            )}
        </Wrapper>
    )
};