import React from 'react';
import {Cell, Group, List, Panel, PanelHeader} from '@vkontakte/vkui';
import { connect } from 'react-redux';
import {goBack} from "../../store/router/actions";
import {BackButton, Checkbox, HeaderTitle} from '../../components/shared';
import {updatePaymentType} from '../../store/cart/actions';
import styled from "styled-components";

const StyledCell = styled(Cell)`
    & .SimpleCell {
        padding-left: 15px;
        padding-right: 12px;
    }
    & h3 {
        font-family: 'Roboto';
        font-weight: 300;
        font-size: 20px;
        margin: 14px 8px;
    }
`;

const SelectPayment = ({goBack, paymentType, updatePaymentType, paymentTypes}) => {
    const selectPayment = (value) => {
        updatePaymentType(value);
        setTimeout(() => goBack(), 200);
    };
    return (
        <Panel id="select-payment">
            <PanelHeader left={<BackButton onClick={() => goBack()}/>} className="flex" style={{justifyContent: 'center'}}>
                <HeaderTitle>Способ оплаты</HeaderTitle>
            </PanelHeader>
            <Group>
            <List>
                {paymentTypes.map((item, key) => 
                    <StyledCell
                        style={{opacity: item.disabled ? .4 : 1}}
                        disabled={item.disabled}
                        onClick={() => selectPayment(item.id)}
                        before={<Checkbox checked={item.id === paymentType}/>}
                    >{item.name}</StyledCell>
                )}
            </List>
            </Group>
        </Panel>
    )
}

const mapStateToProps = state => ({
    paymentType: state.cart.paymentType,
    paymentTypes: state.kitchen.paymentTypes,
})
  
const mapDispatchToProps = {
    goBack,
    updatePaymentType,
};
  

export default connect(mapStateToProps, mapDispatchToProps)(SelectPayment);