import { FixedLayout, FormLayout } from '@vkontakte/vkui';
import React from 'react';
import Sheet from 'react-modal-sheet';
import { StyledButton } from '../shared';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {applyPromo} from '../../store/cart/actions';
import {Input} from "../shared/form";
import FormItem from "../shared/form/FormItem";

const PromocodeSheet = ({open, onClose, promo, applyPromo, loading}) => {
    const [values, setValues] = React.useState({
        promocodeValue: '',
    });

    React.useEffect(() => {
        if (promo) {
            setValues({promocodeValue: promo.code})
        }
    }, [promo]);

    function onChange(e) {
        e.persist();
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    }

    function submit() {
        applyPromo(values.promocodeValue);
    }

    return (
    <Sheet isOpen={open} onClose={() => onClose()} className="bottom-sheet">
        <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
        <div>
            <FormLayout>
                <FormItem
                    status={(!promo || (promo && !promo.available)) ? 'error' : 'valid'}
                    bottom={(!promo || (promo && !promo.available)) && 'Промокод не существует'}
                    top="Промокод">
                    <Input
                        value={values.promocodeValue}
                        onChange={onChange}
                        name="promocodeValue"
                        placeholder="PROMOCODE"
                        medium
                    />
                </FormItem>
            </FormLayout>
        </div>

        
        </Sheet.Content>
        <FixedLayout vertical='bottom' className={`${loading && 'Loading'}`} style={{ display: !open && 'none', padding: 20,  }}>
                    <StyledButton
                        onClick={() => submit()}
                        size="xl"
                        stretched
                        mode="primary"
                    >
                        <span style={{width: '100%', textAlign: 'center'}}>Применить</span>
                    </StyledButton>
                </FixedLayout>
        </Sheet.Container>
        <Sheet.Backdrop />
    </Sheet>
)}

const mapStateToProps = (state) => ({
    phone: state.user.phone,
    loading: state.vkRouter.loading,
    promo: state.cart.promo,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    applyPromo,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(PromocodeSheet);