import React from 'react';
import {connect} from 'react-redux';
import {goBack} from "../../store/router/actions";
import * as gtag from '../../utils/gtag';
import {BackButton, HeaderTitle, ProgressBar} from "../../components/shared";
import {
    Div,
    Panel,
    PanelHeader,
} from "@vkontakte/vkui";
import {selectOrder} from "../../store/orders/actions";
import Item from "../../components/shared/cart/Item";
import Divider from "../../components/shared/cart/Divider";
import Total from "../../components/shared/cart/Total";
import styled from "styled-components";
import {orderStatusMap, orderStatusSteps} from "../../models/order-statuses";

const StatusWrapper = styled.div`
    padding: 12px;
    max-width: 400px;
    
    & h2 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        line-height: 100%;
        color: #30363E;
    }
`;

const OrderView = ({goBack, id, order}) => {
        React.useEffect(() => {
            gtag.event('orders');
        }, [])

        return (
            <Panel id={id}>
                <PanelHeader left={<BackButton onClick={() => goBack()}/>} className="flex" style={{justifyContent: 'center'}}>
                    <HeaderTitle>
                        Заказ #{order.code}
                    </HeaderTitle>
                </PanelHeader>
                <Div style={{minHeight: '80vh', display: 'flex', flexDirection:'column'}}>
                    <div className='content' style={{background: '#fff', width: '100%'}}>
                        <StatusWrapper>
                            <h2>{orderStatusMap[order.status]}</h2>
                            <ProgressBar steps={4} current={orderStatusSteps[order.status]}/>
                        </StatusWrapper>
                        {order.cart.items.map((item, key) => <React.Fragment key={key}>
                                <Item
                                    key={key}
                                    picture={item.image}
                                    title={item.name}
                                    current={item.qty}
                                    max={99}
                                    ingredients={item.ingredients}
                                    price={item.price}
                                    images={item.images}
                                >
                                </Item>
                                <Divider/>
                            </React.Fragment>
                        )}
                    </div>
                    <Total after={<span>{order.cart.price} ₽</span>}>
                        Стоимость
                    </Total>
                    <Total after={<span>{order.cart.deliveryPrice} ₽</span>}>
                        Доставка
                    </Total>
                    <Total after={<strong>{order.cart.totalPrice} ₽</strong>}>
                        <strong>Всего</strong>
                    </Total>
                </Div>
            </Panel>
        )
}

const mapStateToProps = (state) => ({
    order: state.orders.selected,
});

const mapDispatchToProps = {
    goBack,
    selectOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderView);