import styled from "styled-components";
import {Input} from "@vkontakte/vkui";

export default styled(Input)`
    & .Input__el {
        font-weight: 900;
        font-size: ${({medium}) => medium ? `24px` : `40px`} !important;
        margin: ${({medium}) => medium ? `0` : `14px 0`};
        padding: 0;
        color: #30363E;
    }
    & .FormField__border{
        background: #fff;
        border: none;
        border-bottom: 1px solid #30363E;
        border-radius: 0;
        opacity: 0.3;
    }
    ${({notTransparent}) => notTransparent && `.Input__el {
        opacity: 1;
    }`}
`