import * as types from './types';
import {getUserGeoData, getUserPhone, getUserInfo} from '../../services/VK';
import {yandexSearchByGeo} from '../../services/yandex'
import {authorize as httpAuthorize, authSendPhone, authApprovePhone, isMiniapp, loadToken, parseTokenClaims, setToken } from '../../http/auth';
import {closeBottomSheet, setPage} from '../router/actions';
import { resolveKitchen } from '../kitchen/actions';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { LOADING_START, LOADING_FINISH } from '../router/types';
import { checkoutUserFlow } from '../router/flow';
import { upsertCart } from '../cart/actions';

export function populateUserGeo() {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.LOADING,
            });
            const geo = await getUserGeoData();
            if (!geo.available) {
                return;
            }
            dispatch({
                type: types.UPDATE_GEO,
                payload: geo
            });
            dispatch(populateAddress(geo))
        } catch(err) {
            dispatch({
                type: types.ERROR,
            });
        }
    }
}

const awesomeYandexSearchByGeo = AwesomeDebouncePromise(yandexSearchByGeo, 1000, {
    leading: false,
  });
  

export function populateAddress({long, lat}) {
    return async dispatch => {
        dispatch({
            type: types.LOADING,
        });
        try  {
            const addresses = await awesomeYandexSearchByGeo(long, lat);
            if (!addresses || !addresses.length) {
                return
            }
            dispatch({
                type: types.UPDATE_ADDRESS,
                payload: addresses[0]
            });
        } catch (err) {
            console.log(err);
            dispatch({
                type: types.ERROR,
            });
        }
        
    }
}

export function selectGeoOnMap({long, lat, available=true}) {
    return async dispatch => {
        dispatch({
            type: types.UPDATE_GEO,
            payload: {long, lat, available}
        });
        dispatch(populateAddress({long, lat}))
    }
}

export function selectUserGeoAndAddress({geo: {lat, long, available=true}, address}) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_GEO,
            payload: {lat, long, available}
        }); 
        dispatch({
            type: types.UPDATE_ADDRESS,
            payload: address
        });
    } 
}

const LOCATION = 'LOCATION'
export function initialLocation() {
    return dispatch => {
        const locationJSONString = localStorage.getItem(LOCATION)
        try {
            const location = JSON.parse(locationJSONString)
            dispatch(selectUserGeoAndAddress({
                geo: location.geo,
                address: location.address
            }))
        } catch (err) {
            dispatch(populateUserGeo())
        }
    }
}

export function saveLocation() {
    return (dispatch, getState) => {
        const state = getState();
        localStorage.setItem(LOCATION, JSON.stringify({
            geo: state.user.geo,
            address: state.user.address
        }));
        dispatch(resolveKitchen(state.user.geo.lat, state.user.geo.long));
    }
}

export function getUserPhoneFromVK() {
    return async dispatch => {
        try {
            const {phone_number} = await getUserPhone();
            dispatch({
                type: types.PHONE_NUMBER,
                payload: phone_number,
            })
        } catch(err) {
            console.log(err)
        }
    }
}

export function getUserInfoFromVK() {
    return async dispatch => {
        try {
            const payload = await getUserInfo();
            dispatch({
                type: types.USER_INFO,
                payload: payload,
            })
        } catch(err) {
            console.log(err)
        }
    }
}

const checkLocalToken = (dispatch) => {
    if (isMiniapp()) {
        return false;
    }
    const token = loadToken();
    if (!token) {
        return false;
    }

    const claims = parseTokenClaims(token);
    if (!claims || !claims.phone) {
        return false;
    }
    dispatch({
        type: types.PHONE_NUMBER,
        payload: claims.phone,
    });
    setToken(token);
    return true;
}

export function authorize() {
    return async dispatch => {
        dispatch({
            type: types.CHECK_SIGN
        })
        try {
            if (!checkLocalToken(dispatch)) {
                await httpAuthorize();
            }
            dispatch({
                type: types.SIGN_VALID
            })
        } catch(err) {
            dispatch({
                type: types.SIGN_INVALID
            });
            dispatch(setPage('home', 'error'));
        }
    }
}

export function updateAddressDetails(payload) {
    return dispatch => {
        dispatch({
            type: types.UPDATE_ADDRESS_DETAILS,
            payload,
        })
    }
}

export function updateUserDetails(payload) {
    return dispatch => {
        dispatch({
            type: types.UPDATE_USER_DETAILS,
            payload,
        })
    } 
}

export function setNewPhone(phone, newPhone) {
    return async (dispatch) => {
        if (phone === newPhone) {
            dispatch(closeBottomSheet());
            return
        }
        dispatch({
            type: LOADING_START,
        })
        try {
            await authSendPhone(newPhone);
            dispatch({
                type: types.SET_NEW_PHONE,
                payload: newPhone,
            });
            dispatch(setPage('home', 'approve-phone'));
        } catch(err) {
            console.log(err);
        }
        dispatch(upsertCart());
        dispatch({
            type: LOADING_FINISH,
        })
        dispatch(closeBottomSheet());
    }
}

export function repeatSendCode(phone) {
    return async dispatch => {
        dispatch({
            type: LOADING_START,
        })
        try {
            await authSendPhone(phone);
            dispatch({
                type: types.SET_NEW_PHONE,
                payload: phone,
            });
        } catch(err) {
            console.log(err);
        }
        dispatch({
            type: LOADING_FINISH,
        })
    }
}

export function approvePhone(phone, code) {
    return async dispatch => {
        dispatch({
            type: LOADING_START,
        })
        try {
            await authApprovePhone(phone, code);
            dispatch({
                type: types.PHONE_NUMBER,
                payload: phone,
            });
            dispatch(checkoutUserFlow());
        } catch(err) {
            console.log(err);
        }
        dispatch({
            type: LOADING_FINISH,
        })
    }
}