import styled from "styled-components";
import {Textarea} from "@vkontakte/vkui";

export default styled(Textarea)`
    & .Textarea__el {
        font-weight: 500;
        font-size: ${({medium}) => medium ? `18px` : `24px`} !important;
        margin: ${({medium}) => medium ? `0` : `14px 0`};
        padding: 0;
        color: #30363E;
        font-size: ${({medium}) => medium ? `18px` : `24px`} !important;
        border-radius: 0;
    }
    & .FormField__border{
        background: #fff;
        border: none;
        border-bottom: 1px solid #30363E;
        border-radius: 0;
        opacity: 0.3;
    }
`