import styled from "styled-components";
import React from "react";

const Icon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        fill="none"
        viewBox="0 0 22 22"
    >
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M20.663.415a1.22 1.22 0 01.571 1.633L8.308 22l-7.49-9.625a1.22 1.22 0 01.429-1.675 1.23 1.23 0 011.681.426l5.38 5.374L19.024.984a1.229 1.229 0 011.639-.569z"
            clipRule="evenodd"
        ></path>
    </svg>
);

const Checkbox = styled.div`
    background: ${({checked}) => checked ? `#F42456` : '#fff'};    
    border: 2px solid #F42456;
    box-sizing: border-box;
    border-radius: 14px;
    padding: 7px;
    min-width: 36px;
    min-height: 36px;
    display: flex;
    transition: all 0.2s ease 0s;
`

export default function ({checked}) {
    return (
        <Checkbox checked={checked}>
            <Icon/>
        </Checkbox>
    )
};