import * as types from './types';

const initialState = {
    activeOrder: null,
    selected: null,
    list: [],
    total: 0,
}

export default function (state=initialState, {type, payload}) {
    switch (type) {
        case types.SELECT_ORDER: {
            return {
                ...state,
                selected: payload,
            }
        }
        case types.APPEND_ORDERS: {
            return {
                ...state,
                total: payload.total,
                list: [
                    ...state.list,
                    ...payload.items,
                ]
            }
        }
        case types.SET_ACTIVE_ORDER: {
            return {
                ...state,
                activeOrder: payload,
                selected: state.selected && state.selected.id === payload.id ? payload : state.selected,
            }
        }
        default: {
            return state;
        }
    }
}