import { kitchen } from '../../http/kitchen';
import * as types from './types';

export const resolveKitchen = (lat, lng) => async dispatch => {
    dispatch({
        type: types.RESOLVE_KITCHEN_START,
    });
    try { 
        const payload = await kitchen(lat, lng);
        dispatch({
            type: types.RESOLVE_KITCHEN_SUCCESS,
            payload,
        })
    } catch (err) {
        dispatch({
            type: types.RESOLVE_KITCHEN_ERROR,
            payload: err,
        })
        console.log(err);
    }
}

export const setRecipe = (recipe) => dispatch => dispatch({
    type: types.SET_RECIPE,
    payload: recipe,
})

export const switchFilter = (category, selectedRecipe) => dispatch => dispatch({
    type: types.SET_SELECTED_CATEGORY,
    payload: {category, selectedRecipe},
});