import React from "react";

function Icon({...props}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="10"
      fill="none"
      viewBox="0 0 12 10"
      {...props}
    >
      <path
        stroke="url(#paint0_linear)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 4.538L4.58 9 11 1"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="11"
          x2="0.446"
          y1="-0.778"
          y2="9.146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F42456"></stop>
          <stop offset="1" stopColor="#F42456"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Icon;
