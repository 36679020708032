import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="4"
      fill="none"
      viewBox="0 0 20 4"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M.593 2.711A1.111 1.111 0 011.446.89h17.108a1.111 1.111 0 010 2.222H1.446c-.33 0-.642-.146-.853-.4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
