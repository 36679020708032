import * as types from './types';

export const loadingStart = () => (
    {
        type: types.LOADING_START,
    }
);

export const loadingFinish = () => (
    {
        type: types.LOADING_FINISH,
    }
);

export const setFlow = (payload) => (
    {
        type: types.SET_FLOW,
        payload,
    }
);

export const setStory = (story, initial_panel) => (
    {
        type: types.SET_STORY,
        payload: {
            story: story,
            initial_panel: initial_panel,
        }
    }
);

export const setPage = (view, panel) => (
    {
        type: types.SET_PAGE,
        payload: {
            view: view,
            panel: panel
        }
    }
);

export const setPageCleanHistory = (view, panel) => (
    {
        type: types.SET_PAGE_CLEAN_HISTORY,
        payload: {
            view: view,
            panel: panel
        }
    }
);

export const goBack = () => (
    {
        type: types.GO_BACK
    }
);

export const openPopout = (popout) => (
    {
        type: types.OPEN_POPOUT,
        payload: {
            popout: popout
        }
    }
);

export const closePopout = () => (
    {
        type: types.CLOSE_POPOUT
    }
);

export const openModal = (id) => (
    {
        type: types.OPEN_MODAL,
        payload: {
            id
        }
    }
);

export const closeModal = () => (
    {
        type: types.CLOSE_MODAL
    }
);

export const openBottomSheet = (id) => (
    {
        type: types.OPEN_BOTTOM_SHEET,
        payload: {
            id,
        }
    }
)

export const closeBottomSheet = () => (
    {
        type: types.CLOSE_BOTTOM_SHEET,
    }
)

export const resetHistory = () => (
    {
        type: types.RESET_HISTORY
    }
);
