import React from 'react';

import { connect } from 'react-redux';
import { goBack, openModal } from "../../store/router/actions";
import {
  Panel,
  PanelHeader,
} from "@vkontakte/vkui";
import * as gtag from '../../utils/gtag';
import { BackButton, HeaderTitle } from '../../components/shared';
import { paymentDone } from '../../store/cart/actions';
import { pollOrder } from '../../store/orders/actions';

function Checkout({ id, goBack, orderID,  paymentDone, loading, pollOrder, paymentURL}) {

  const ref = React.useRef();

  React.useEffect(() => {
    gtag.event('checkout');
    const listener = (e) => {
      console.log(e);

      if (e.data === '[AppState] SET_SUCCESS') {
        gtag.event('purchase')
        pollOrder(orderID);
        paymentDone();
      }
    }
    window.addEventListener("message", listener, false)
    return () => {
      window.removeEventListener("message", listener, false)
    }
  }, []);


  return (
    <Panel id={id}>
      <PanelHeader left={<BackButton onClick={() => goBack()} />} className="flex" style={{ justifyContent: 'center' }}>
        <HeaderTitle>
          Оплата
        </HeaderTitle>
      </PanelHeader>
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe ref={ref} src={paymentURL} style={{border: 0, height: '100vh'}}/>
    </Panel>
  );
}

const mapStateToProps = state => ({
    orderID: state.cart.orderID,
    paymentURL: state.cart.paymentURL ? state.cart.paymentURL : `https://kuuda-edu.hi-it.spb.ru/payment/yookassa?token=${state.cart.token}`,
    loading: state.vkRouter.loading,
})

const mapDispatchToProps = {
  goBack,
  paymentDone,
  openModal,
  pollOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);