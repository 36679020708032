import styled from 'styled-components';
import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="28"
            fill="none"
            viewBox="0 0 12 28"
        >
            <path
                fill="#fff"
                d="M7.275.09L0 1.54l4.322 18.157 5.925-1.18L7.275.088zm1.75 27.747c1.91-.38 3.29-2.33 2.912-4.363-.42-1.97-2.442-3.24-4.352-2.86-2.014.401-3.343 2.341-2.923 4.31.378 2.033 2.35 3.315 4.363 2.913z"
            ></path>
        </svg>
    );
}

const Wrapper = styled.div`
    padding: 18px;
    
    background: #F49608;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    color: #FFFFFF;
    
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 17px;
    
    display: flex;
    align-items: center;
    transition: all 0.2s ease 0s;

    & > div {
      flex: 1 1 auto;
      margin: 5px;
    }
`
export default ({text}) => (
    <Wrapper>
        <div>
            <Icon/>
        </div>
        <div>
            {text}
        </div>
    </Wrapper>
)