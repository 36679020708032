import React from 'react';
import {connect} from 'react-redux';

import {setPage, goBack} from "../../store/router/actions";
import {authorize} from '../../store/user/actions';
import * as gtag from '../../utils/gtag';
import error from '../../../img/error.png'

import {
    Div,
    FixedLayout,
    Panel,
    PanelHeader,
    Title,
} from "@vkontakte/vkui";
import { StyledButton } from '../../components/shared';

function Error({id, authorize}) {
    React.useEffect(() => {
        gtag.event('view_orders');
    }, [])
    return (
        <Panel id={id}>
            <PanelHeader/>
            <Div style={{minHeight: '80vh', display: 'flex', flexDirection:'column', textAlign: 'center'}}>
                <Title>Произошла неожиданная ошибка<br/></Title>
                    <div style={{width: '100%', height: '50vh', background: `url(${error}) no-repeat center center / contain`, padding: '50% 0 0 0'}}/>
            </Div>
            <FixedLayout vertical='bottom' className="MainButton"  style={{padding: 20,  }}>
                        <StyledButton
                            onClick={() => authorize()}
                            size="xl" 
                            stretched
                            mode="primary"
                            className="MainButton__Content"
                        >
                                Повторить
                        </StyledButton>
                </FixedLayout>
        </Panel>
    );
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    setPage,
    goBack,
    authorize,
};

export default connect(mapStateToProps, mapDispatchToProps)(Error);
