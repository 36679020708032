const _ = require('lodash');

function _fixViewportHeight() {
    function _onResize(event) {
        console.log(`height: ${document.documentElement.clientHeight - window.visualViewport.height + 24}px`)
        document.documentElement.style.cssText = `--fixed-bottom-padding-bottom: ${document.documentElement.clientHeight-window.visualViewport.height + 24}px`;
    }

    window.addEventListener('resize', _.debounce(_onResize, 125, {
        leading: true,
        maxWait: 250,
        trailing: true
    }));

    _onResize();

    // FIX FOR INSTAGRAM
    if (navigator.userAgent.match(/instagram/i)) {
        setTimeout(() => _onResize(), 3000);
    }
}

_fixViewportHeight();