import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="16"
            fill="none"
            viewBox="0 0 18 16"
        >
            <path
                fill="#fff"
                d="M10.707 15.707a1 1 0 01-1.414-1.414L14.586 9H1a1 1 0 01-.993-.883L0 8a1 1 0 011-1h13.586L9.293 1.707A1 1 0 019.21.387l.083-.094a1 1 0 011.414 0l7 7 .073.082a3.307 3.307 0 01.024.03 1.027 1.027 0 01.185.447c.007.048.011.097.011.148l-.004-.09a.96.96 0 01.004.07V8a1.223 1.223 0 01-.011.149 1.122 1.122 0 01-.06.222 1.008 1.008 0 01-.222.336l.08-.09a1.003 1.003 0 01-.08.09l-7 7z"
            ></path>
        </svg>
    );
}

export default Icon;
