import * as types from './types';
import * as gtag from '../../utils/gtag';
import { checkout } from '../../http/orders';
import { v4 as uuidv4 } from 'uuid';
import { upsert } from '../../http/cart';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { loadingFinish, loadingStart, setPageCleanHistory, closeBottomSheet } from '../router/actions';

const flat = (data) => data.reduce((r, e) => Array.isArray(e) ? r = r.concat(flat(e)) : r.push(e) && r, [])

const awesomeUpsertCart = AwesomeDebouncePromise(upsert, 1000, {
    leading: true,
});

export const applyPromo = (promocode) => async (dispatch, getState) => {
    dispatch({
        type: types.SET_PROMO,
        payload: {
            code: promocode, 
            available: false,
            appliedValue: 0,
        }
    })
    await dispatch(upsertCart());
    const {cart: {promo}} = getState();
    if (promo.available) {
        dispatch(closeBottomSheet());
    }
};

export const upsertCart = () => async (dispatch, getState) => {
    const {cart, user: {firstName, lastName, address, geo: {lat, long}}, kitchen: {id}} = getState();
    const upsertCartReq = {
        address: {
            ...address, 
            lat, 
            lng: long
        },
        firstName,
        lastName,
        kitchenID: id,
        promo: cart.promo ? cart.promo.code : undefined,
        items: cart.items.map(
            ({name, id, qty, ingredients}) => ({
                name, id, qty, ingredients,
            })),
    };
    dispatch(loadingStart());
    const resp = await (awesomeUpsertCart(cart.cartID, upsertCartReq));
    dispatch(loadingFinish());
    dispatch({
        type: types.UPDATE_PRICES,
        payload: {
            price: resp.discountPrice,
            deliveryPrice: resp.deliveryPrice,
            promo: resp.promo,
        }
    });
    
}

export const addItemToCart = payload => dispatch => {
    dispatch({
        type: types.ADD_ITEM_TO_CART,
        payload: {
            ...payload,
            ingredients: flat(payload.components.map(
                ({ingredients}) => ingredients.filter(
                    ({qty}) => qty > 0).map(({name, id, qty, quantum}) => ({name, id, qty, quantum})
                )
            )),
        },
    });
    dispatch(upsertCart());
    gtag.event('add_to_cart', {
        items: [payload],
    });
}

export const removeItemFromCart = payload => dispatch => {
    dispatch({
        type: types.REMOVE_ITEM_FROM_CART,
        payload,
    });
    dispatch(upsertCart());
    gtag.event('remove_from_cart', {
        items: [payload]
    });
}

export const updatePaymentType = (paymentType) => dispatch => dispatch({
    type: types.UPDATE_PAYMENT_TYPE,
    payload: paymentType,
});

export const createOrder = (cartID, paymentType, phone) => async dispatch => {
    try {
        dispatch(loadingStart());
        const payload = await checkout(cartID, paymentType, phone);
        dispatch({
            type: types.CHECKOUT,
            payload
        })
        dispatch(loadingFinish());
    } catch (err) {
        console.log(err)
    }
}

const CART_ID = "CART_ID";
export const populateCartID = () => (dispatch) => {
    let cartID = localStorage.getItem(CART_ID);
    if (!cartID) {
        cartID = uuidv4();
        localStorage.setItem(CART_ID, cartID)
    }
    dispatch({
        type: types.UPDATE_CART_ID,
        payload: cartID,
    })
}

export const paymentDone = () => (dispatch) => {
    localStorage.removeItem(CART_ID);
    dispatch({
        type: types.PAYMENT_DONE,
    })
    dispatch(populateCartID);
    dispatch(setPageCleanHistory('home', 'base'))
}