import * as types from './types';

const initialState = {
    orderID: null,
    token: null,
    cartID: null,
    price: 0,
    deliveryPrice: 0,
    paymentType: 0,
    items: [],
    phone: null,
    paymentURL: null,
    promo: null,
}

export default function(state=initialState, {type, payload}) {
    switch (type) {
        case types.SET_PROMO: {
            return {
                ...state,
                promo: payload,
            }
        }
        case types.UPDATE_PRICES: {
            return {
                ...state,
                price: payload.price,
                deliveryPrice: payload.deliveryPrice,
                promo: payload.promo,
            }
        }
        case types.PAYMENT_DONE: {
            return {
                ...initialState,
                items: [],
            };
        }
        case types.UPDATE_CART_ID: {
            return {
                ...state,
                cartID: payload,
            }
        }
        case types.ADD_ITEM_TO_CART: {
            const {items} = state;
            let updated = false;
            items.forEach(item => {
                if (item.id === payload.id && JSON.stringify(item.ingredients) === JSON.stringify(payload.ingredients) ) {
                    item.qty = item.qty + payload.qty;
                    updated = true;
                }
            })
            if (!updated) {
                items.push(payload);
            }
            return {
                ...state,
                items: [...items],
            }
        }
        case types.REMOVE_ITEM_FROM_CART: {
            
            const {items} = state;
            let removeIndex = -1;
            let i = 0;
            items.forEach(item => {
                switch (payload.category) {
                    case ('drinks'):
                        if (item.id === payload.id) {
                            item.qty = item.qty - 1;
                            if (item.qty === 0) {
                                removeIndex = i;
                            }
                        }
                        break;
                    default:
                        if (item.id === payload.id && (JSON.stringify(item.ingredients) === JSON.stringify(payload.ingredients)) ) {
                            item.qty = item.qty - 1;
                            if (item.qty === 0) {
                                removeIndex = i;
                            }
                        }
                }
                ++i;
            })
            if (removeIndex > -1) {
                items.splice(removeIndex, 1);
            }
            return {
                ...state,
                items: [...items],
            }
        }
        case types.SET_CART_CANDIDATE: {
            return {
                ...state,
                candidate: payload
            }
        }
        case types.CHECKOUT: {
            return {
                ...state,
                orderID: payload.id,
                token: payload.token,
                paymentURL: payload.paymentURL,
            }
        }
        case types.UPDATE_PAYMENT_TYPE: {
            return {
                ...state,
                paymentType: payload,
            }
        }
        default: {
            return state;
        }
    }
}
