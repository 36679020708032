import jwt_decode from 'jwt-decode';
import http from './http';

const JWT = 'JWT';

export const saveToken = (token) => {
    localStorage.setItem(JWT, token)
}

export const loadToken = () => {
    return localStorage.getItem(JWT)
}

export const parseTokenClaims = (token) => {
    try {
        const claims = jwt_decode(token);
        if (claims.exp < (new Date().getTime() + 1) / 1000) {
            return null;
        }
        return claims;
    } catch(err) {
        return null;
    }
}

export const getAuthParams = (string) => {
    const r = {};
    for (let p of new URLSearchParams(string)) {
        if (!p[0].startsWith("vk_") && p[0] !== "sign") {
            continue
        }
        r[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
    }
    return r;
}

export const isMiniapp = () => {
    const body = getAuthParams(window.location.search);
    return !!body.sign
}

export const authorize = async () => {
    const body = getAuthParams(window.location.search);
    const resp = await http.post(
        body.sign 
        ? '/auth/vk-miniapp'
        : '/auth/anonymous', 
    body);
    setToken(resp.data.token);
}

export const authSendPhone = async (phone) => {
    return (await http.get(`/auth/phone/`, {
        params: {
            phone,
        }
    })).data;
}

export const authApprovePhone = async (phone, code) => {
    const resp = await http.get(`/auth/phone/`, {
        params: {
            phone,
            code,
        }
    });
    setToken(resp.data.token);
    saveToken(resp.data.token);
}

export const setToken = (token) => {
    http.defaults.headers.common['Authorization'] = `JWT ${token}`;

}