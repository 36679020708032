import http from './http';

const mapPaymentType = {
    0: 'online',
    1: 'courier_card',
    2: 'cash',
};

export const checkout = async (cartID, paymentType, phone) => {
    return (await http.post("/orders",
        {
            cartID,
            paymentType: mapPaymentType[paymentType],
            phone
        }
    )).data;
};

export const getOrder = async (orderID) => {
    return (await http.get(`/orders/${orderID}`)).data;
}

export const getOrders = async ({limit = 100, offset = 0}) => {
    return (await http.get(`/orders`, {
        params: {
            limit,
            offset,
        }
    })).data;
}