import http from './http';

export const geoSuggests = async (query) => {
    return (await http.get("/geo/suggests", {
        params: {
            query,
        }
    })).data.items || [];
};

export const reverseGeocode = async (lat, lon) => {
    return (await http.get("/geo/reverse", {
        params: {lat, lon}
    })).data;
};