import { SELECT_STORIES_ITEM } from './types';

/*
[{
        name: 'Поке',
        id: 'poke',
        icon: require('../../../img/stories/icons/poke.png'),
        viewed: false,
        stories: [
            {
                url: require('../../../img/stories/poke/1.png'),
                duration: 3000,
                header: {
                    heading: 'Поке',
                    subheading: 'Что такое поке',
                    profileImage: require('../../../img/stories/icons/poke.png'),
                },
            },
            {
                url: require('../../../img/stories/poke/2.png'),
                duration: 6000,
                header: {
                    heading: 'Поке',
                    subheading: 'Что такое поке',
                    profileImage: require('../../../img/stories/icons/poke.png'),
                },
            },
            {
                url: require('../../../img/stories/poke/3.png'),
                duration: 6000,
                header: {
                    heading: 'Поке',
                    subheading: 'Что такое поке',
                    profileImage: require('../../../img/stories/icons/poke.png'),
                },
            },
        ],
    }, {
        name: 'Шаверма',
        id: 'shawarma',
        icon: require('../../../img/stories/icons/shawarma.png'),
        viewed: false,
        stories: [
            {
                // url: require('../../../img/stories/shawarma/1.png'),
                duration: 3000,
                header: {
                    heading: 'Шаверма',
                    subheading: 'Какую мы можем сделать?',
                    profileImage: require('../../../img/stories/icons/shawarma.png'),
                },
            },
        ],
    }, {
        name: 'Новинки',
        id: 'new',
        icon: require('../../../img/stories/icons/delivery.png'),
        viewed: false,
        stories: [
            {
                url: require('../../../img/stories/new/1.png'),
                duration: 3000,
                header: {
                    heading: 'Новинки',
                    subheading: 'Что новенького в конструкторе еды',
                    profileImage: require('../../../img/stories/icons/delivery.png'),
                },
            },
        ],
    }]
*/

const initialState = {
    selected: null,
    items: [],
}

export default function (state=initialState, {type, payload}) {
    switch (type) {
        case SELECT_STORIES_ITEM:
            const i = state.items.findIndex(item => item.id === payload);
            if (i < 0) {
                return
            }
            const selected = state.items[i];
            return {
                selected,
                viewed: true,
                items: [
                    ...state.items.slice(0, i),
                    {
                        ...selected,
                        viewed: true,
                    },
                    ...state.items.slice(i+1, state.items.length),
                ]
            } 
        default:
            return state;
    }
}