import styled from "styled-components";
import {Button} from "@vkontakte/vkui";

export default styled(Button)`
    width: 44px;
    height: 36px;
    background: rgba(255, 255, 255, 0);
    // border: 1px solid #D1D1D1;
    box-sizing: border-box;
    // box-shadow: inset 0px 2px 0px rgba(0, 0, 0, 0.06);
    border-radius: 0;
    
    .Button__content {
        padding: 6px 0 6px;
        font-size: 17px;
    }

    transition: all 0.2s ease 0s;
    transform: scale(1);

    &.Tappable--active, &:hover {
        transform: scale(1.1);
    }
`;