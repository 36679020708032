import axios from "axios";
import * as gtag from "../utils/gtag";
import { reverseGeocode } from "../http/geo";

const key = `f62790cb-9ec8-418e-bba1-9061a7a5f4c5`;

//Ok?
const yandexSearchSPB = async (search) => {
  const resp = await axios
  .get(`https://geocode-maps.yandex.ru/1.x/`, {
    params: {
      format: `json`,
      geocode: search,
      apikey: key,
      ll: `30.3141,59.9386`,
      rspn: 1,
      spn: `1, 1`,
    },
  });
  const data = resp.data;
  return data.response.GeoObjectCollection.featureMember;
};

const yandexSearchMoscow = async (search) => {
  const resp = await axios
  .get(`https://geocode-maps.yandex.ru/1.x/`, {
    params: {
      format: `json`,
      geocode: search,
      apikey: key,
      ll: `37.6155,55.7522`,
      rspn: 1,
      spn: `1,1`,
    },
  });
  const data = resp.data;
  return data.response.GeoObjectCollection.featureMember;
};

const filterKind = ["house", "street"];

const checkKind = (item) =>
  item.GeoObject &&
  item.GeoObject.metaDataProperty &&
  item.GeoObject.metaDataProperty.GeocoderMetaData &&
  filterKind.indexOf(item.GeoObject.metaDataProperty.GeocoderMetaData.kind) >
    -1;

const yandexSearch = async (search) => {
  const res = await Promise.all([
    yandexSearchSPB(search),
    yandexSearchMoscow(search),
  ]);
  gtag.event("location-search", {
    search,
    results: res[1].length + res[0].length,
  });
  return [
    ...res[1].filter(checkKind), // .filter(checkLocality),
    ...res[0].filter(checkKind), // .filter(checkLocality),
  ];
};


// TODO: когда-то тут был яндекс-геокодер, но теперь тут запрос на мой маленький скромный уютный бекенд
const yandexSearchByGeo = async (x, y) => {
  console.log(x,y );
  const resp = await reverseGeocode(y, x);
  console.log(resp);
  return [{
    name: resp.value,
    description: resp.value,
    text: resp.value,
    precision: !!resp.house ? 'exact' : '',
  }];
};

export { yandexSearch, yandexSearchByGeo };
