import {Card, Title} from "@vkontakte/vkui";
import {Minus as MinusIcon, Plus as PlusIcon} from "../../icons";
import MinusButton from "./MinusButton";
import React from "react";
import PlusButton from "./PlusButton";
import QTYButton from "./QTYButton";
import styled from "styled-components";
import Price from "./Price";

const ButtonGroup = styled.div`
    display: flex;
`;

const Description = styled.div`
    margin-top: 10px;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #999999;
    font-family: Roboto;

    & div {
        padding: 0 2px;
    }
`

export default function ({images, picture, title, current, max, ingredients, onClick, onIncreaseClick, onReduceClick, price}) {
    return (
        <div className="Card__Ingredient" style={{paddingBottom: 12}}>

            <Card
                onClick={() => onClick && onClick()}
                className="ingredient"
                style={{
                    width: 72,
                    height: 72,
                    background: picture && `#fff url(${picture}) no-repeat center center / ${(images && images.scale) || '100%'}`,
                    borderRadius: 12,
                }}/>
            <div className="Card__Content">
                <Title style={{fontSize: 17}}>
                    {title}
                </Title>
                <Description>
                    {ingredients && ingredients.map((item, key) => item.qty > 0 &&
                        <div key={key}> &#8226; {item.name} &times; {Math.round(item.qty * item.quantum)}&nbsp;г
                        </div>
                    )}
                </Description>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <ButtonGroup style={{marginTop: '12px', justifyContent: 'flex-start'}}>
                        {onReduceClick && current > 0 && <MinusButton
                            onClick={onReduceClick}
                            className="qty"
                            style={{padding: 0}}
                            mode="secondary"> <MinusIcon/> </MinusButton>}
                        {current > 0 && <QTYButton
                            className="qty"
                            style={{padding: 0}}
                            mode="tertiary"> {!onIncreaseClick && !onIncreaseClick && `× `}{current} </QTYButton>}
                        {onIncreaseClick && max > current && <PlusButton
                            onClick={onIncreaseClick}
                            className="qty"
                            style={{padding: 0}}
                            mode="secondary"> <PlusIcon/> </PlusButton>}
                    </ButtonGroup>
                    <Price>
                        {price * current}&nbsp;₽
                    </Price>
                </div>
            </div>
        </div>
    )
}