import * as types from './types';

const initialState = {
    userID: null,
    firstName: null,
    lastName: null,
    geo: {
        lat: null, 
        long: null,
    },
    address: {
        name: '',
        flat: '',
        floor: '',
        entrance: '',
        comment: '',
    },
    valid: null,
    phone: '',
    newPhone: '',
    newPhoneStart: null,
}

export default function (state = initialState, {type, payload}) {
    switch (type) {
        case types.SET_NEW_PHONE: {
            return {
                ...state,
                newPhone: payload,
                newPhoneStart: new Date(),
            }
        }
        case types.CHECK_SIGN: {
            return {
                ...state,
                valid: null,
            }
        }
        case types.SIGN_INVALID: {
            return {
                ...state,
                valid: false,
            }
        }
        case types.SIGN_VALID: {
            return {
                ...state,
                valid: true,
            }
        }
        case types.USER_INFO: {
            // dirty hack
            let address = state.address
            if (!state.address.defined) {
                address = {
                    ...address,
                    // name: payload.city.title,
                }
            }
            return {
                ...state,
                userID: payload.id,
                firstName: payload.first_name,
                lastName: payload.last_name,
                address,
            }
        }
        case types.ERROR: {
            return {
                ...state,
            }
        }
        case types.LOADING: {
            return {
                ...state,
            }
        }
        case types.UPDATE_ADDRESS: {
            return {
                ...state,
                address: {
                    ...state.address,
                    ...payload,
                    defined: true,
                    flat: '',
                    floor: '',
                    entrance: '',
                    comment: '',
                },
            }
        }
        case types.UPDATE_GEO: {
            return {
                ...state,
                geo: payload,
                address: {
                    ...state.address,
                    flat: '',
                    floor: '',
                    entrance: '',
                    comment: '',
                }
            }
        }
        case types.PHONE_NUMBER:
            return {
                ...state,
                phone: payload,
            }
        case types.UPDATE_ADDRESS_DETAILS:
            return {
                ...state,
                address: {
                    ...state.address,
                    ...payload
                },
            }
        case types.UPDATE_USER_DETAILS:
            return {
                ...state,
                ...payload,
            }
        default: {
            return state;
        }
    }
}