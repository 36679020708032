import {combineReducers} from "redux";
import {routerReducer} from './router';
import {vkuiReducer} from './vk/reducers';
import user from './user';
import cart from './cart';
import stories from './stories';
import kitchen from './kitchen';
import orders from './orders';
import { connectRouter } from 'connected-react-router';

export default (history) => combineReducers({
    vkui: vkuiReducer,
    vkRouter: routerReducer,
    user,
    cart,
    stories,
    kitchen,
    orders,
    router: connectRouter(history),
});