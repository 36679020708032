import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="44"
      fill="none"
      viewBox="0 0 35 44"
    >
      <path
        fill="url(#paint0_linear)"
        d="M4.426 26.137l.076.108c.093.138.19.271.287.417l11.003 15.61a1.735 1.735 0 002.82 0L29.59 26.676c.106-.146.207-.288.308-.417l.068-.1a14.88 14.88 0 002.423-7.583 14.858 14.858 0 00-1.839-7.741 15.097 15.097 0 00-5.585-5.728A15.346 15.346 0 0017.2 3c-2.733 0-5.415.728-7.764 2.106a15.096 15.096 0 00-5.586 5.728 14.858 14.858 0 00-1.84 7.74 14.88 14.88 0 002.424 7.584l-.008-.021zm12.776-14.372a6.39 6.39 0 013.519 1.054 6.272 6.272 0 012.332 2.806c.48 1.143.605 2.4.36 3.614a6.23 6.23 0 01-1.733 3.202 6.36 6.36 0 01-3.242 1.712 6.407 6.407 0 01-3.66-.356 6.318 6.318 0 01-2.842-2.303 6.199 6.199 0 01-1.067-3.475c0-1.659.667-3.25 1.855-4.423a6.374 6.374 0 014.478-1.831z"
      ></path>
      <defs>
        <filter
          id="filter0_d"
          width="34.4"
          height="44"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.679167 0 0 0 0 0.4075 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="5.45"
          x2="49.017"
          y1="-32"
          y2="-30.784"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F42456"></stop>
          <stop offset="1" stopColor="#F42456"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Icon;
