import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {switchFilter} from '../../store/kitchen/actions';
import {closePopout, goBack, openModal, openPopout, setPage, openBottomSheet} from '../../store/router/actions';
import { addItemToCart, removeItemFromCart } from '../../store/cart/actions';
import { Panel, PanelHeader, FixedLayout, Tappable, Button} from "@vkontakte/vkui"
import {IconButton, HeaderTitle, Card, ProgressBar, Carousel, Alert} from '../../components/shared';
import {selectStoriesItem} from '../../store/stories/actions';
import { sortViewedSelector } from '../../store/stories/selectors';
import { Cart, Minus as MinusIcon, Plus as PlusIcon } from '../../components/icons';
import UserButton from '../../components/shared/UserButton';
import {selectOrder} from "../../store/orders/actions";

const StyledPositionTags = styled.div`
    position: absolute;
    bottom: 10px;
    left: 10px;
    padding: 6px 14px;
    font-size: 20px;
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #F42456;
    letter-spacing: 4px;
`;

const ButtonGroup = styled.div`
    display: flex;
`;

const Minus = styled(Button)`
    width: 40px;
    height: 40px;
    background: #F42456;
    border-radius: 100px;

    transition: all 0.2s ease 0s;
    transform: scale(1);

    &.Tappable--active, &:hover {
        transform: scale(1.1);
    }

    & .Button__in {
        height: 36px;    
    }

    & .Button__content {
        color: #fff;
        font-weight: 500;
        font-size: 17px;
        padding: 0;
    }
`;

const Plus = styled(Button)`
    height: 40px;
    background: #F42456;
    border-radius: 8px 0 0 8px;
    border-radius: 100px;
    color: #333;
    border-radius: 100px;
    transform: ${({current}) => current > 0 && `rotate(180deg)`};

    line-height: 28px;
    width: ${({current}) => current > 0 ? '40px' : 'auto'};
    padding: ${({current}) => current > 0 ? '0' : '0 26px'};
    min-height: 0;

    & .Button__in {
        height: 36px;    
    }

    & .Button__content {
        color: #fff;
        font-weight: 500;
        font-size: 17px;
        padding: 0;
    }

    transition: all 0.2s ease 0s;
    transform: scale(1);

    &.Tappable--active, &:hover {
        transform: scale(1.1);
    }
`;

const QTY = styled(Button)`
    width: 44px;
    height: 36px;
    background: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    border-radius: 0;
    
    .Button__content {
        font-size: 17px;
        padding: 0;
    }
`;

const StyledCategoryBlockPicture = styled.div`
    height: 199px;
    border-radius: 20px 20px 0 0;
    background: ${({images}) => `url(${images && images.large}) no-repeat center center / ${(images && images.scale) || 'contain'}`};
    background-blend-mode: normal, multiply;
    position: relative;
`;


const Title = styled.h2`
    font-weight: 900;
    font-size: 28px;
    line-height: 100%;
    font-family: 'Roboto';
    color: #F42456;
    margin: 0 0 30px 0;
`;


const StyledCategoryBlock = styled.div`
    padding: 0 18px 0 18px;
    overflow: hidden;

    ${Title} {
        margin-top: 30px;
    }
`;

const StyledPositionManage = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 7px 20px;
`;

const StyledPosition = styled(Tappable)`
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    margin: 15px 0 15px 0;
    transition: all 0.2s ease 0s;
    width: 100%;
    background-color: #fff;

    &.scale, &:hover {
        transform: scale(0.95);
    }

    ${({scale}) => scale === 'contain' && `
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        background: url(${require('../../../img/position-0.png')}) no-repeat left top / 45%;
        background-color: #fff;

        ${StyledCategoryBlockPicture} {
            width: 40%;
            height: 160px;
        }

        ${StyledPositionManage} {
            flex-direction: column;
            width: 60%;
        }
    `}
`;



const StyledPositionPrice = styled.div`
    background: #FFE7E8;
    border-radius: 100px;
    color: #F42456;
    font-size: 17px;
    font-family: 'Roboto';
    padding: 12px 20px;
    display: flex;
    align-items: center;
    min-width: 76px;
    text-align: center;
    justify-content: center;
    margin: 12px 0;
    display: inline-block;
`;

const StyledPositionTitle = styled.div`
    font-weight: 900;
    font-size: 17px;
    line-height: 120%;
    font-family: 'Roboto';
    align-items: center;
    display: flex;
    padding: 0 30px 0 6px;
    height: 70px;
    color: #30363E;
`;

const StyledWrapper = styled.div`
    display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    
    ${StyledPosition}:only-child {
        margin: 10px 0 14px 20px;
    }

    ${StyledPosition}:not(:only-child) {
        // width: 100%;
        min-width: 50%;
        margin: 20px 0 14px 20px;
        display: flex;
        flex-direction: column;
        background: #fff;

        ${StyledCategoryBlockPicture} {
            height: 145px;
            width: 100% !important;
        }

        ${StyledPositionManage} {
            flex-direction: column;
            text-align: center;
            padding: 10px;
            display: block;
            width: auto;

            ${ButtonGroup} {
                justify-content: center;
            }

            ${StyledPositionPrice} {
                background: #F5F5F5;
                display: block;
                border-radius: 10px;
                margin: 0;
            }

            ${StyledPositionTitle} {
                width: 100%;
                padding: 0;
                text-align: center;
                display: flex;
                justify-content: center;
            }
        }
    }

    ${StyledPosition}:last-child {
        margin: 20px 0 14px 20px;
    }

    &:after {
        content: "";
        padding: 10px;
    }
`;

const StatusContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const StatusWrapper = styled.div`
    padding: 18px;
    max-width: 400px;
`;

const NotFound = styled.div`
    width: 100%;
    height: 85vh; 
    background: url(${require('./../../../img/location.png')}) no-repeat center center / contain;

    & div {
        padding: 24px;
        text-align: center;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
    }
`;

const StyledPanel = styled(Panel)`
    background: linear-gradient(to top,#f5f5f5,rgba(245,245,245,.98),rgba(245,245,245,.94),rgba(245,245,245,.88),rgba(245,245,245,.8),rgba(245,245,245,.71),rgba(245,245,245,.61),rgba(245,245,245,.5),rgba(245,245,245,.39),rgba(245,245,245,.29),rgba(245,245,245,.2),rgba(245,245,245,.12),rgba(245,245,245,.06),rgba(245,245,245,.02),rgba(245,245,245,0));
    min-height: 100vh !important;
`;

const StatusTitle = styled.h2`
    font-weight: 900;
    font-size: 20px;
    line-height: 100%;
    color: #30363E;
    margin: 14px 0px;
    font-family: 'Roboto';
`;

const BodyWrapper = styled.div`
    padding-bottom: ${Math.abs(window.innerHeight - window.screen.height)}px;
`;

function Home({selectOrder, kitchenStatus, addItemToCart, removeItemFromCart, id, address, switchFilter, categories, setPage, cartItems, openBottomSheet, activeOrder, loading, recipes}) {

    const currentQty = cartItems.reduce(((result, item) => {
        result[item.id] = item.qty
        return result
    }), {})

    function select(filter, recipe) {
        switchFilter(filter, recipe);
        if (filter === 'drinks') {
            setPage('home', 'select');
            return
        }
        openBottomSheet('constructor');
    }

    function openOrder(order) {
        selectOrder(order)
        setPage('home', 'order');
    }

    return (
        <StyledPanel id={id}>
            <PanelHeader className="flex" fixed={true} left={<UserButton onClick={() => setPage('home', 'menu')}/>}>
                <HeaderTitle onClick={() => setPage('home', 'map')}>
                    {address.name}
                </HeaderTitle>
            </PanelHeader>
            <BodyWrapper>
            {kitchenStatus === 'disable' &&
                <StatusWrapper>
                    <Alert text="Ресторан временно не работает, а приложение запущено в тестовом режиме"/>
                </StatusWrapper>
            }
            {activeOrder && (activeOrder.status === 'paid' || activeOrder.status === 'approved') &&
                <StatusWrapper>
                    <Card onClick={() => openOrder(activeOrder)}>
                        <StatusTitle>
                            Отправляем заказ на кухню...
                        </StatusTitle>
                        <ProgressBar steps={5} current={1}/>
                        <p>Заказ на {activeOrder.cart.totalPrice} ₽</p>
                    </Card>
                </StatusWrapper>}
            {activeOrder && activeOrder.status === 'start_cooking' && <StatusContainer>
                <StatusWrapper>
                    <Card onClick={() => openOrder(activeOrder)}>
                        <StatusTitle>
                            Уже начали готовить...
                        </StatusTitle>
                        <ProgressBar steps={5} current={2}/>
                        <p>Заказ на {activeOrder.cart.totalPrice} ₽</p>
                    </Card>
                </StatusWrapper>
            </StatusContainer>}
            {activeOrder && activeOrder.status === 'created' &&
                <StatusWrapper>
                    <Card onClick={() => openOrder(activeOrder)}>
                        <StatusTitle>
                            Связываемся с банком для подтверждения платежа...
                        </StatusTitle>
                        <ProgressBar steps={5} current={0}/>
                        <p>Заказ на {activeOrder.cart.totalPrice} ₽</p>
                    </Card>
                </StatusWrapper>
            }
            {activeOrder && activeOrder.status === 'handover' &&
            <StatusWrapper>
                <Card onClick={() => openOrder(activeOrder)}>
                    <StatusTitle>
                        Курьер спешит к вам
                    </StatusTitle>
                    <ProgressBar steps={5} current={4}/>
                    <p>Заказ на {activeOrder.cart.totalPrice} ₽</p>
                </Card>
            </StatusWrapper>
            }
            {activeOrder && activeOrder.status === 'done' &&
            <StatusWrapper>
                 <Card onClick={() => openOrder(activeOrder)}>
                     <StatusTitle>
                         Заказ доставлен
                     </StatusTitle>
                     <ProgressBar steps={5} current={5}/>
                     <p>Заказ на {activeOrder.cart.totalPrice} ₽</p>
                </Card>
             </StatusWrapper>
            }
            {activeOrder && activeOrder.status === 'canceled' &&
                <StatusWrapper>
                    <Card onClick={() => openOrder(activeOrder)}>
                         {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                         <StatusTitle>Заказ отменен&nbsp;&nbsp;😰</StatusTitle>
                         <ProgressBar steps={5} current={0}/>
                         <p>Заказ на {activeOrder.cart.totalPrice} ₽</p>
                     </Card>
                </StatusWrapper>
            }

            {categories.length > 0 && categories.map((category, key) => <StyledCategoryBlock key={key}> 
                
                <Carousel 
                    title={category.name} 
                    subtitle={category.description} 
                    background={category.background}>
                    
                    <StyledWrapper>
                    {recipes.map((recipe, key) => recipe.category === category.id && 
                        <StyledPosition activeMode={recipe.components.length > 0 && 'scale'} onClick={() => (recipe.components && recipe.components.length && select(category.id, recipe)) } scale={`${(recipe.images && recipe.images.scale) || 'contain'}`}>
                            <StyledCategoryBlockPicture images={recipe.images}>
                            {recipe.emojies && recipe.emojies.length > 0 && 
                                <StyledPositionTags>
                                    {recipe.emojies.map((e) => <>{e}</>)}
                                </StyledPositionTags>
                            }
                            </StyledCategoryBlockPicture>
                            <StyledPositionManage>
                                <StyledPositionTitle>
                                    {recipe.displayName || recipe.name}
                                </StyledPositionTitle>
                                {recipe.price && <div>


                                {recipe.components && recipe.components.length ? <StyledPositionPrice>
                                    {recipe.price}&nbsp;₽
                                </StyledPositionPrice> :
                                
                                    <ButtonGroup>
                                    {currentQty[recipe.id] > 0 && <Minus 
                                                        onClick={() => removeItemFromCart({...recipe})}
                                                        className="qty" 
                                                        style={{padding: 0}} 
                                                        mode="secondary"> <MinusIcon/> </Minus>}
                                    {currentQty[recipe.id] > 0 && <QTY
                                                        className="qty" 
                                                        mode="tertiary"> {currentQty[recipe.id]} </QTY>}
                                    {<Plus 
                                                        onClick={() => addItemToCart({...recipe, qty: 1})}
                                                        current={currentQty[recipe.id]}
                                                        mode="secondary"> {currentQty[recipe.id] > 0 ? <PlusIcon/> : `${recipe.price} ₽`} </Plus>}
                                    </ButtonGroup>
                                
                                } 

                                </div>}
                            </StyledPositionManage>
                        </StyledPosition>
                        )}
                    </StyledWrapper>
                    </Carousel>
                </StyledCategoryBlock>)}
            
            {categories.length === 0 && !loading && 
                    <NotFound>
                        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                        <div>К сожалению, мы еще не доставляем еду по вашему адресу 😰</div>
                    </NotFound>}             
            {cartItems.length > 0 && <FixedLayout vertical='bottom' className="FixedIconButton">
                <IconButton
                    id="cartButton"
                    onClick={() => setPage('home', 'cart')}
                    size="xl" 
                    mode="primary"
                    activeMode='scale'
                    counter={cartItems.length}
                >
                        <Cart/>
                </IconButton>
                
            </FixedLayout>}
            </BodyWrapper>
        </StyledPanel>
    )
}

const mapStateToProps = state => ({
    address: state.user.address,
    catalog: state.catalog,
    cartItems: state.cart.items,
    categories: state.kitchen.categories,
    recipes: state.kitchen.recipes,
    storyItems: sortViewedSelector(state),
    activeOrder: state.orders.activeOrder,
    loading: state.kitchen.loading,
    kitchenStatus: state.kitchen.status,
})

const mapDispatchToProps = {
    setPage,
    goBack,
    openPopout,
    closePopout,
    openModal,
    switchFilter,
    selectStoriesItem,
    openBottomSheet,
    addItemToCart,
    removeItemFromCart,
    selectOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);