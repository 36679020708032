import styled from 'styled-components';
import { Tappable } from '@vkontakte/vkui';

export default styled(Tappable)`
    background: #FFFFFF;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
    border-radius: 24px;
    padding: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    color: #30363E;
    font-family: 'Roboto';
    cursor: pointer;
`;