import React from 'react';

import {connect} from 'react-redux';
import {setPage, goBack} from "../../store/router/actions";
import Icon24Add from '@vkontakte/icons/dist/24/add';
import {
    Panel,
    Separator,
    PanelHeader,
    List,
    SimpleCell,
    Group,
    FixedLayout,
} from "@vkontakte/vkui";
import * as gtag from '../../utils/gtag';
import { BackButton, HeaderTitle, StyledButton } from '../../components/shared';
import styled from 'styled-components';

const StyledSimpleCell = styled(SimpleCell)`
    & .Title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        color: #30363E;
    }
`;

function Help({id, goBack, setPage}) {

    React.useEffect(() => {
        gtag.event('view_menu')
    }, []);

    const [selected, setSelected] = React.useState(null)

    function toggle(name) {
        setSelected(selected === name ? null : name)
    }

    return (
        <Panel id={id}>
            <PanelHeader left={<BackButton onClick={() => goBack()}/>} className="flex" style={{justifyContent: 'center'}}>
                <HeaderTitle>
                    Помощь
                </HeaderTitle>
            </PanelHeader>
            <Group className="group__help">
              <List>
                <div className={`open ${selected === 'how-it-works' && 'opened'}`}>
                <StyledSimpleCell multiline onClick={() => toggle('how-it-works')} after={<Icon24Add/>}>Как всё работает?</StyledSimpleCell>
                <div className="toggled" multiline>
                    Курьеры на велосипедах развозят заказы, приготовленные на нашей кухне в вашем районе. Радиус доставки – 1.5 километра, поэтому получается так быстро, а блюда остаются горячими.
                <br/><br/>
                    Кухня похожа на ресторан, в котором нет зала с посетителями. Повара получают заказ с описанием базового рецепта и используемых ингредиентов, готовят его, а после передают курьерам. 
                    На приготовление блюд уходит до 30 минут, а на доставку – еще 15 минут.
                </div>
                </div>
                <Separator/>
                <div className={`open ${selected === 'where-it-works' && 'opened'}`}>
                <StyledSimpleCell multiline onClick={() => toggle('where-it-works')} after={<Icon24Add/>}>Где вы работаете?</StyledSimpleCell>
                <div className="toggled" multiline>
                    Мы работаем в Москве и Санкт-Петербурге.
                </div>
                </div>
                <Separator/>
                <div className={`open ${selected === 'when-it-works' && 'opened'}`}>
                <StyledSimpleCell multiline onClick={() => toggle('when-it-works')} after={<Icon24Add/>}>В какое время можно сделать заказ?</StyledSimpleCell>
                <div className="toggled" multiline>
                    Мы работаем с 9 утра и до 12 часов вечера. Если вы оформили заказ за 10 минут до закрытия, то мы всё равно его доставим.
                </div>
                </div>
                <Separator/>
                <div className={`open ${selected === 'min-sum' && 'opened'}`}>

                <StyledSimpleCell multiline onClick={() => toggle('min-sum')} after={<Icon24Add/>}>Есть ли минимальная сумма заказа?</StyledSimpleCell>
                <div className="toggled" multiline>
                    Нет, мы доставим заказ на любую сумму.
                </div>
                </div>
                <Separator/>
                <div className={`open ${selected === 'free-delivery' && 'opened'}`}>

                <StyledSimpleCell onClick={() => toggle('free-delivery')} after={<Icon24Add/>}>Доставка бесплатная?</StyledSimpleCell>
                <div className="toggled" multiline>
                    Да, у нас бесплатная доставка.
                </div>
                </div>
                <Separator/>
                <div className={`open ${selected === 'warranty' && 'opened'}`}>

                <StyledSimpleCell multiline onClick={() => toggle('warranty')} after={<Icon24Add/>}>Как гарантируете свежесть продуктов?</StyledSimpleCell>
                <div className="toggled" multiline>
                   Два раза в день наши сотрудники проверяют срок годности товаров и дополнительно внешний вид фруктов и овощей. 
                   В холодильниках мы поддерживаем температуру 2 – 4 градуса и -18 градусов в морозильных камерах.
                </div>
                </div>
                <Separator/>
                <div className={`open ${selected === 'review' && 'opened'}`}>

                <StyledSimpleCell multiline onClick={() => toggle('review')} after={<Icon24Add/>}>Где почитать отзывы на сервис?</StyledSimpleCell>
                <div className="toggled" multiline>
                   Отзывы, рецепты, обновления меню и другие новости сервиса мы будем публиковать в нашей <a href="https://vk.com/kuudaedu">группе</a>.
                </div>
                </div>
                <Separator/>
                <div className={`open ${selected === 'quarantine' && 'opened'}`}>

                <StyledSimpleCell multiline onClick={() => toggle('quarantine')} after={<Icon24Add/>}>Как вы работаете в период карантина?</StyledSimpleCell>
                <div className="toggled" multiline style={{marginBottom: '48px'}}>
                    Заказы доставляются бесконтактно. Курьер оставляет заказ у двери, чтобы вы могли забрать его самостоятельно, и затем отходит на два метра.
                    Если вы столкнулись с тем, что курьер нарушил эти правила, пожалуйста, напишите нам.
                </div>
                </div>
              </List>
            </Group>
            <FixedLayout vertical='bottom' className="MainButton"  style={{padding: 20,  }}>
                <a href="https://vk.com/im?media=&sel=-63346997" rel="noopener noreferrer" style={{textDecoration: 'none'}} target="_blank">
                    <StyledButton
                        size="xl" 
                        stretched
                        mode="primary"
                        className="MainButton__Content"
                    >
                        Связаться с нами
                    </StyledButton>
                </a>
            </FixedLayout>
        </Panel>
    );
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = {
    goBack,
    setPage
};

export default connect(mapStateToProps, mapDispatchToProps)(Help);