export const CREATED = 'created';
export const PAID = 'paid';
export const APPROVED = 'approved';
export const START_COOKING = 'start_cooking';
export const HANDOVER = 'handover';
export const DONE = 'done';
export const CANCELED = 'canceled';

export const orderStatusSteps = {
    [CREATED]: 1,
    [PAID]: 1,
    [APPROVED]: 1,
    [START_COOKING]: 2,
    [HANDOVER]: 3,
    [DONE]: 4,
    [CANCELED]: 4,
}

export const orderStatusMap = {
    [CREATED]: 'Заказ создан',
    [PAID]: 'Отправляем заказ на кухню...',
    [APPROVED]: 'Заказ подтвержден',
    [START_COOKING]: 'Начали готовить',
    [HANDOVER]: 'Заказ у курьера',
    [DONE]: 'Доставлен',
    [CANCELED]: 'Отменен',
};
