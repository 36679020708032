import React from 'react';

import {connect} from 'react-redux';
import {goBack, openModal, setPage} from "../../store/router/actions";
import {Cell, Group, List, Panel, PanelHeader,} from "@vkontakte/vkui";
import * as gtag from '../../utils/gtag';
import {BackButton, HeaderTitle} from '../../components/shared';
import styled from 'styled-components';
import {Edit} from "../../components/icons";

const MenuItem = styled(Cell)`
    & .Title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: #30363E;
    }
`

const StyledGroup = styled(Group)`
    margin: 0 6px;
`;

const Icon = styled.div`
    & svg {
        margin-top: 24px;
    }
`;

const Profile = styled.div`
    margin: 0 12px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & h2 {
        font-weight: 900;
        font-size: 42px;
        color: #30363E;
        font-family: 'Roboto';
        font-size: 42px;
        margin-bottom: 0;
        
        & small {
            display: block;
            font-style: normal;
            font-weight: 300;
            font-size: 17px;
            line-height: 100%;            
            color: #999999;
            margin-top: 12px;
        }
    }
`;

function Menu({id, goBack, setPage, user}) {

    React.useEffect(() => {
        gtag.event('view_menu');
    }, []);

    return (
        <Panel id={id}>
            <PanelHeader left={<BackButton onClick={() => goBack()}/>} className="flex"
                         style={{justifyContent: 'center'}}>
                <HeaderTitle>
                    Меню
                </HeaderTitle>
            </PanelHeader>

            <StyledGroup>
                {user.firstName && <Profile>
                    <h2>
                        {user.firstName}
                        {user.phone && <small>{user.phone}</small>}
                    </h2>
                    <Icon onClick={() => setPage('home', 'profile')}>
                        <Edit/>
                    </Icon>
                </Profile>}
                <List>
                    <MenuItem onClick={() => setPage('home', 'orders')}>
                        История заказов
                    </MenuItem>
                    <MenuItem onClick={() => setPage('home', 'map')}>
                        Адреса
                    </MenuItem>
                    <MenuItem onClick={() => setPage('home', 'help')}>
                        Помощь
                    </MenuItem>
                </List>
            </StyledGroup>
        </Panel>
    );
}

const mapStateToProps = state => ({
    ordersTotal: state.orders.total,
    user: state.user
})

const mapDispatchToProps = {
    setPage,
    goBack,
    openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);