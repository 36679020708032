import { getAuthToken } from '../../services/VK';
import { createOrder, populateCartID } from '../cart/actions';
import { resolveKitchen } from '../kitchen/actions';
import { initialOrdersHistory, pollOrder } from '../orders/actions';
import { initialLocation, authorize, getUserInfoFromVK } from '../user/actions';
import { goBack, setFlow, setPage, openBottomSheet } from './actions';
import * as gtag from '../../utils/gtag';
import { paymentDone } from '../cart/actions';
import * as VK from '../../utils/vk-event';

export const initialUserFlow = () => async (dispatch, getState) => {
    
    await Promise.all([
        dispatch(initialLocation()),
        dispatch(authorize()),
        dispatch(populateCartID()),
    ]);
    Promise.resolve(Promise.resolve([
        dispatch(getAuthToken([])),  
        dispatch(getUserInfoFromVK()),
        dispatch(initialOrdersHistory()),
    ]));
    const {user: {geo: {lat, long}}} = getState();
    if (lat && long) {
        dispatch(resolveKitchen(lat, long));
        dispatch(setPage('home', 'base'));
        return
    }
    dispatch(setPage('home', 'map'));
};

export const FLOW_CHECKOUT = 'FLOW_CHECKOUT';

export const checkoutUserFlow = () => async (dispatch, getState) => {
    const {user: {address: {flat}, phone}, cart: {cartID, paymentType}, vkRouter: {flow}} = getState();
    if (flow !== FLOW_CHECKOUT) {
        dispatch(goBack())
        return;
    }
    if (!phone) {
        dispatch(openBottomSheet('phone'));
        return;
    }
    if (!flat) {
        dispatch(setPage('home', 'address-form'));
        return;
    }
    await dispatch(createOrder(cartID, paymentType, phone));
    if (paymentType === 0) {
        dispatch(setPage('home', 'checkout'));
    } else {
        const orderID = getState().cart.orderID;
        dispatch(paymentDone());
        dispatch(pollOrder(orderID));
    }
    gtag.event('purchase');
    VK.event('purchase');
    dispatch(setFlow(null));
};