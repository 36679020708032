import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="29"
            height="29"
            fill="none"
            viewBox="0 0 29 29"
        >
            <path
                fill="#F42456"
                d="M14.082 28.596l2.847-.57a7.239 7.239 0 003.715-1.994l6.27-6.286c3.299-3.594-1.607-8.507-5.188-5.201l-6.27 6.287a7.27 7.27 0 00-1.987 3.724l-.57 2.855a1.01 1.01 0 00.986 1.205c.065 0 .131-.007.197-.02zm11.41-12.626c.644.619.644 1.732 0 2.351l-6.27 6.287a5.237 5.237 0 01-2.687 1.441l-1.37.275.274-1.373a5.259 5.259 0 011.439-2.695l6.27-6.286c.313-.314.73-.487 1.172-.487.443 0 .86.173 1.173.487z"
            ></path>
            <path
                fill="#F42456"
                d="M10.054 26.765H6.03c-3.325 0-6.03-2.712-6.03-6.046V6.661C0 3.328 2.705.616 6.03.616h13.667c3.325 0 6.03 2.712 6.03 6.045v3.736c-.053 1.337-1.958 1.336-2.01 0V6.661a4.03 4.03 0 00-4.02-4.03H6.03a4.03 4.03 0 00-4.02 4.03V20.72a4.03 4.03 0 004.02 4.03h4.024c1.334.054 1.333 1.963 0 2.016z"
            ></path>
        </svg>
    );
}

export default Icon;
