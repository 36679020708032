import React from 'react';
import {connect} from 'react-redux';
import TextLoop from "react-text-loop";
import {setPage, goBack, openModal} from "../../store/router/actions";
import {setActiveTab, setScrollPositionByID} from "../../store/vk/actions";
import { YMaps, Map, GeolocationControl, } from 'react-yandex-maps';
import {selectGeoOnMap, saveLocation} from '../../store/user/actions';
import {
    Panel,
    FixedLayout,
    PanelHeader,
    Title
} from "@vkontakte/vkui";

import * as gtag from '../../utils/gtag';
import styled from 'styled-components';
import { BackButton, StyledButton } from '../../components/shared';
import { Marker } from '../../components/icons';

const StyledHeader = styled(PanelHeader)`
    position: absolute;
    z-index: 9990;
`;

const StyledInvite = styled.div`
    z-index: 9990;
    position: absolute;
    left: 24px;
    top: 80px;
    transition: all 0.7s ease, color 0.2s linear;
    & h1 {
        margin: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 38px;
        line-height: 44px;
        color: #474747;
        text-shadow: 0px 1px 12px #FFFFFF;
    }
`;

const StyledMarker = styled.div`
    transform: translate(-50%, -100%);
    z-index: 9999;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: all 0.2s ease 0s;
`;

const StyledTitle = styled(Title)`
    padding: 12px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    
    /* or 94% */
    letter-spacing: 0.374px;
    
    color: #474747;
`;

const StyledFixedLayout = styled(FixedLayout)`
    padding: 0 24px 24px 24px;
    transition: all 0.7s ease, color 0.2s linear;
`;

const StyledCursor = styled.span`
    color: #F42456;
    font-size: 30px;
    font-weight: 900;

    animation-name: blink;
    animation-timing-function: linear;
    animation-duration: 1s;
    animation-iteration-count: infinite;
`;

const Submit = styled(StyledButton)`
    padding: 4px;
    background: #F42456;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    
    .Button__content {
        justify-content: space-around;
    }
`

const StyledMap = styled.div`
    height:100%; 
    witdh: 100%;
    &:after {
        pointer-events: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        content: ' ';
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.23291323365283612) 80%, rgba(255,255,255,1) 95%, rgba(255,255,255,1) 100%);
    }
`;

const StyledPanel = styled(Panel)`
    .dragged {
        ${StyledMarker} {
            top: 47%;
        }

        ${StyledInvite} {
            top: -200px;
        }

        ${StyledFixedLayout} {
            bottom: -200px;
        }
    }
`;

function LocationPanel({id, goBack, setPage, geo, address, selectGeoOnMap, saveLocation, goBackAvailable}){
    const [center, setCenter] = React.useState([geo.lat, geo.long])
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (geo.lat === null) {
            setCenter([59.87181776022665, 30.37822466535949]);
        }
        gtag.event('view-map');
    }, [])

    const onLoad = (yamps) => {
            if (address.name) {
                return
            }
            yamps.geolocation.get().then(
                ({geoObjects}) => setCenter(geoObjects.position),
            )
    }

    const onActionBegin = (e) => {
        if (ref.current) {
            ref.current.classList.add('dragged');
        }
    }

    const onActionEnd = (e) => {
        if (ref.current) {
            ref.current.classList.remove('dragged');
        }
    }

    const onBoundsChange = (e) => {
        const coords = e.originalEvent.map.getCenter();
        selectGeoOnMap({lat: coords[0], long: coords[1]});
        gtag.event('select-location-on-map');
    }

    const submit = () => {
        saveLocation();
        setPage('home', 'base');
    }

    return (
            <StyledPanel id={id}>
                <StyledHeader 
                    transparent={true}
                    left={goBackAvailable && <BackButton onClick={() => goBack()}/>}/>
                <div ref={ref}>
                <div style={{display: 'flex', flexDirection:'column', height:'100vh'}}>
                    <StyledMap>
                        {!goBackAvailable && <StyledInvite>
                            <h1>Куда доставить&nbsp;
                                <TextLoop>
                                    <span style={{color: "#F0A82F"}}>
                                        шаверму?
                                    </span>
                                    <span style={{color: "#f02f77"}}>
                                        бургер?
                                    </span>
                                    <span style={{color: "#f0482f"}}>
                                        стейк?
                                    </span>
                                </TextLoop></h1>
                        </StyledInvite>}
                        <StyledMarker>
                            <Marker/>
                        </StyledMarker>
                    <YMaps version={"2.1"} query={{apikey: "0253dc06-98b3-4837-916c-7ab7e44d469b"}}>
                        <Map state={{ center, zoom: 17 }}
                            onLoad={onLoad}
                            modules={['geolocation', 'geocode']}
                            onActionBegin={onActionBegin}
                            onActionEnd={onActionEnd}
                            onBoundsChange={onBoundsChange}
                            width="100%" height="100%">
                                <GeolocationControl options={{ float: 'right', position: {top: 100, right: 12 }}} />
                        </Map>
                    </YMaps>
                    </StyledMap>
                </div>
                
                <StyledFixedLayout vertical='bottom'  style={{padding: 24,  }}>
                    <StyledTitle onClick={() => setPage("home", "selectLocation")}>
                        {address.name || 'Введите адрес'}<StyledCursor className="blink">&#124;</StyledCursor>
                    </StyledTitle>
                    <Submit
                        onClick={submit}
                        size="xl" 
                        stretched 
                        disabled={address.precision !== 'exact' && address.precision !== 'number'}
                        className="MainButton__Content"
                    >
                        Везите сюда
                    </Submit>
                </StyledFixedLayout>
                </div>
            </StyledPanel>
        );

}

const mapStateToProps = (state) => ({
    geo: state.user.geo,
    address: state.user.address,
    loading: state.user.loading,
    goBackAvailable: state.vkRouter.panelsHistory.home && state.vkRouter.panelsHistory.home.length > 1
});

const mapDispatchToProps = {
    setPage,
    setActiveTab,
    setScrollPositionByID,
    goBack,
    openModal,
    selectGeoOnMap,
    saveLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationPanel);
