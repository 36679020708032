import React from 'react';

import {connect} from 'react-redux';
import {setPage, goBack, openModal} from "../../store/router/actions";
import {
    Panel,
    PanelHeader,
    PanelHeaderBack,
} from "@vkontakte/vkui";
import * as gtag from '../../utils/gtag';
import ReactInstaStories from 'react-insta-stories';
import styled from 'styled-components';

const StyledStory = styled.div`
    
    & div:first-child {
        & div:first-child {
            bottom: 0;
        }
        & div:nth-child(2) {
            & div:first-child {
                & div:first-child {
                    width: 100%;
                }
            }
            & div:last-child {
                top: inherit !important;
                bottom: 26px;
                font-family: 'Comfortaa';
            }
        }
    }
`

const StyledHeader = styled(PanelHeader)`
    position: absolute;
    z-index: 9990;
`;

function StoryPanel({id, goBack, setPage, selected}) {

    React.useEffect(() => {
      gtag.event('view_menu');
    }, []);

    return (
        <Panel id={id}>
            <StyledHeader 
                transparent={true}
                left={<PanelHeaderBack onClick={() => goBack()}/>}/>
            <StyledStory>
            <ReactInstaStories
                 width="100%"
                 height="100vh"
                 stories={selected.stories} 
                //  onAllStoriesEnd={() => goBack()}
                 storyStyles={{ 
                    display: 'flex',
                    height: '100vh',
                    width: '100%',
                    objectFit: 'cover'
                }}/>
                 </StyledStory>
        </Panel>
    );
}

const mapStateToProps = state => ({
    selected: state.stories.selected,
})

const mapDispatchToProps = {
    setPage,
    goBack,
    openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoryPanel);