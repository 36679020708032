import { v4 as uuidv4 } from 'uuid';

(() => {
    if (process.env.NODE_ENV === "development") {
        return
    }
    function getObjectUrlString(string) {
        let search = string
        let objectUrl = search === "" ? null : search.split("&").reduce((prev, curr) => {
            const [key, value] = curr.split("=");
            prev[decodeURIComponent(key)] = decodeURIComponent(value);
            return prev;
        }, {});
        return objectUrl
    }

    function getObjectSearchUrlParams(string) {
        const r = {};
        for (let p of new URLSearchParams(string)) {
            r[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
        }
        return r;
    }
    
    const searchParams = getObjectSearchUrlParams(window.location.search);
    const params = getObjectUrlString(window.location.hash.replace('#', '')) || {};
    window.gtag && window.gtag('config', 'G-EBT95QELC3', {
        ...params,
        ...searchParams,
    });
})()


export const event = (eventName, eventParams = {}) => window.gtag && process.env.NODE_ENV !== "development" && window.gtag('event', eventName, eventParams);


export const purchase = (cartItems, price) => event('purchase', {
    items: cartItems,
    value: price,
    price,
    transaction_id: uuidv4(),
});

export const checkoutProgress = (cartItems, price) => event('checkout_progress', {
    items: cartItems,
    value: price,
    price,
});

export const beginCheckout = (cartItems, price) => event('begin_checkout', {
    items: cartItems,
    value: price,
    price,
});

