import React from 'react';
import styled from 'styled-components';

const Bar = styled.div`
    height: 6px;
    background: ${({isActive}) => isActive ? '#F42456' : '#D2D2D2'};
    border-radius: 100px;
    width: ${({width}) => width};
    margin: 2px;
`;

const Wrapper = styled.div`
    display: flex;

    ${Bar}:first-child {
        margin-left: -2px;
    }

    ${Bar}:last-child {
        margin-right: -2px;
    }
`;

export default function ProgressBar({steps, current}) {
    const width = 100/steps;
    const el = [];
    for (const n of Array(steps).keys()) {
        el.push(n)
    }
    return (
        <Wrapper>
            {el.map((n) => <Bar width={`${width}%`} isActive={n<=current}/>)}
        </Wrapper>
    )
}