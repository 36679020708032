export const UPDATE_GEO = 'UPDATE_GEO';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const UPDATE_ADDRESS_DETAILS = 'UPDATE_ADDRESS_DETAILS';
export const LOADING = 'LOADING';
export const ERROR = 'ERROR';
export const PHONE_NUMBER = 'PHONE_NUMBER';
export const USER_INFO = 'USER_INFO';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const SIGN_VALID = 'SIGN_VALID';
export const SIGN_INVALID = 'SIGN_INVALID';
export const CHECK_SIGN = 'CHECK_SIGN';
export const SET_NEW_PHONE = 'SET_NEW_PHONE';