import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {setPage, goBack, setFlow, openBottomSheet} from "../../store/router/actions";
import {getUserPhoneFromVK} from "../../store/user/actions";
import {addItemToCart, removeItemFromCart, upsertCart} from '../../store/cart/actions';
import empty from '../../../img/empty.png';
import * as gtag from '../../utils/gtag';
import {FLOW_CHECKOUT} from '../../store/router/flow';
import {
    Panel,
    PanelHeader,
    FixedLayout,
    List,
    CellButton,
    IconButton
} from "@vkontakte/vkui";
import {StyledButton, HeaderTitle, ButtonHelper, BackButton, Divider} from '../../components/shared';

import { ArrowRight } from '../../components/icons';
import { checkoutUserFlow } from '../../store/router/flow';
import Item from "../../components/shared/cart/Item";
import Total from "../../components/shared/cart/Total";

const Address = styled(CellButton)`
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    min-height: 44px;

    .SimpleCell__main {
        padding-bottom: 9px;
    }
`;

const Promocode = styled(CellButton)`
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    min-height: 44px;

    .SimpleCell__main {
        padding-bottom: 9px;
    }
`;

const Spacing = styled.div`
    height: 11px;
`;


function Cart({setFlow, promo, openBottomSheet, cartItems, phone, id, setPage, address, addItemToCart, removeItemFromCart, goBack, price, deliveryPrice, getUserPhoneFromVK, paymentType, checkoutUserFlow, loading, upsertCart, priceLevels}) {
    // eslint-disable-next-line no-unused-vars
    const [hint, setHint] = React.useState('');
    React.useEffect(() => {
        gtag.beginCheckout(cartItems, price + deliveryPrice);
        if (!phone) {
            getUserPhoneFromVK();
        }
    }, []);

    React.useEffect(() => {
        for (const i in priceLevels) {
            if (priceLevels[i].maxCost <= price) {
                continue;
            }
            if (priceLevels[i+1]) {
                setHint(`Закажите на ${priceLevels[i+1].maxCost} ₽ для доставки за ${priceLevels[i+1].deliveryCost}`);
                return
            }
            setHint(`Закажите на ${priceLevels[i].maxCost} ₽ для бесплатной доставки`);
            return;
        }
    }, [price]);
    
    const submit = () => {
        upsertCart();
        setFlow(FLOW_CHECKOUT);
        checkoutUserFlow();
    };

    return (
        <Panel id={id}>
            <PanelHeader left={<BackButton onClick={() => goBack()}/>} className="flex" style={{justifyContent: 'center'}}>
                
                <HeaderTitle>
                     Корзина
                </HeaderTitle>
            </PanelHeader>

            <div style={{background: '#F7F7F7', minHeight: '78vh', display: 'flex', flexDirection:'column', paddingBottom: cartItems.length > 0 ? 196 : 0}}>
                {cartItems.length === 0 && 
                    <div style={{width: '100%', height: '50vh', background: `url(${empty}) no-repeat center center / contain`, padding: '50% 0 0 0'}}/>
                    
                }
                    <div className='content' style={{background: '#fff', width: '100%'}}>
                        {cartItems.map((item, key) => <React.Fragment key={key}>
                            <Item
                                key={key}
                                picture={item.image}
                                title={item.name}
                                current={item.qty}
                                max={99}
                                ingredients={item.ingredients}
                                onReduceClick={() => removeItemFromCart({...item, qty: 1})}
                                onIncreaseClick={() => addItemToCart({...item, qty: 1})}
                                price={item.price}
                                images={item.images}
                                >
                            </Item>
                            <Divider/>
                            </React.Fragment>
                        )}
                    </div>
                    {cartItems.length > 0 && <div>
                        
                        <List>
                            <Address
                                onClick={() => openBottomSheet('phone')} 
                                after={<IconButton icon={<ArrowRight/>}/>}>
                                {phone || 'Введите телефон...'}
                            </Address>
                            <Divider/>
                            <Address
                                onClick={() => setPage('home', 'address-form')} 
                                after={<IconButton icon={<ArrowRight/>}/>}
                                >
                                {(address && (address.flat ? `${address.name}, ${address.flat}` : address.name)) || 'Введите адрес...'}
                            </Address>
                            <Divider/>
                            <Address
                                onClick={() => setPage('home', 'select-payment')}
                                after={<IconButton icon={<ArrowRight/>}/>}>
                                {paymentType === 0 && 'Картой онлайн'}
                                {paymentType === 1 && 'Картой курьеру'}
                                {paymentType === 2 && 'Наличными'}
                            </Address>
                            <Divider/>
                            <Promocode
                                onClick={() => openBottomSheet('promocode')} 
                                after={!promo || (promo && !promo.available) ? <IconButton icon={<ArrowRight/>}/> : <span>- {promo.appliedValue} ₽</span>}>
                                {!promo || (promo && !promo.available) ? `Ввести промокод` : `Промокод: ${promo.code}`}
                            </Promocode>
                            <Divider/>
                            <Spacing/>
                            <Total after={<span>{price} ₽</span>}>
                                Стоимость
                            </Total>
                            <Total after={<span>{deliveryPrice} ₽</span>}>
                                Доставка
                            </Total>
                            <Total after={<strong>{price + deliveryPrice} ₽</strong>}>
                                <strong>К оплате</strong>
                            </Total>
                        </List>
                    </div>}
                </div>
                {cartItems.length > 0 && <FixedLayout vertical='bottom' className={`${loading && 'Loading'}`} style={{padding: 20,  }}>
                        {/* {hint && <Hint>
                            <span>{hint}</span>
                        </Hint>} */}
                        <StyledButton
                            id="checkoutButton"
                            onClick={submit}
                            size="xl"
                            mode="primary"
                            stretched 
                        >
                                <ButtonHelper>{price + deliveryPrice} ₽</ButtonHelper>
                                    <span>Далее</span>
                                <ButtonHelper>25-35 мин.</ButtonHelper>
                        </StyledButton>
                </FixedLayout>}
        </Panel>
    );
}

const mapStateToProps = (state) => {
    return {
        cartID: state.cart.cartID,
        phone: state.user.phone,
        activeTab: state.vkui.activeTab,
        address: state.user.address,
        cartItems: state.cart.items,
        price: state.cart.price,
        deliveryPrice: state.cart.deliveryPrice,
        paymentType: state.cart.paymentType,
        loading: state.vkRouter.loading,
        priceLevels: state.kitchen.priceLevels,
        promo: state.cart.promo,
    };
};

const mapDispatchToProps = {
    setPage,
    addItemToCart,
    removeItemFromCart,
    goBack,
    getUserPhoneFromVK,
    checkoutUserFlow,
    setFlow,
    upsertCart,
    openBottomSheet,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
