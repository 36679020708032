import { FixedLayout, FormLayout } from '@vkontakte/vkui';
import React from 'react';
import Sheet from 'react-modal-sheet';
import { StyledButton } from '../shared';
import { checkoutUserFlow } from '../../store/router/flow';
import { setNewPhone } from '../../store/user/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {FormItem, Input} from "../shared/form";

const PhoneBottomSheet = ({open, onClose, phone, setNewPhone, loading}) => {
    const [values, setValues] = React.useState({
        newPhone: '',
        code: '',
    });

    React.useEffect(() => {
        setValues({newPhone: phone})
    }, [phone]);

    function onChange(e) {
        e.persist();
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    }

    function submit() {
        // eslint-disable-next-line no-useless-escape
        setNewPhone(phone, values.newPhone.replace(/([,"'\-.~!@#$%^&*()_+=–’\s`{}\[\]\|\\:;"<>\/?])+/g, '').replace(/^(-)+|(-)+$/g,''));
    }

    return (
    <Sheet isOpen={open} onClose={() => onClose()} className="bottom-sheet">
        <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
        <div>
            <FormLayout>
                <FormItem top="Телефон">
                    <Input
                        value={values.newPhone}
                        onChange={onChange}
                        name="newPhone"
                        placeholder="+79651234567"
                        />
                </FormItem>
            </FormLayout>
        </div>

        
        </Sheet.Content>
        <FixedLayout vertical='bottom' className={`${loading && 'Loading'}`} style={{ display: !open && 'none', padding: 20,  }}>
                    <StyledButton
                        onClick={() => submit()}
                        size="xl"
                        stretched
                        mode="primary"
                    >
                        <span style={{width: '100%', textAlign: 'center'}}>Далее</span>
                    </StyledButton>
                </FixedLayout>
        </Sheet.Container>
        <Sheet.Backdrop />
    </Sheet>
)}

const mapStateToProps = (state) => ({
    phone: state.user.phone,
    loading: state.vkRouter.loading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    checkoutUserFlow,
    setNewPhone,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(PhoneBottomSheet);