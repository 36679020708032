import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import DayJS from 'react-dayjs';
import {setPage, goBack} from "../../store/router/actions";
import * as gtag from '../../utils/gtag';
import empty from '../../../img/empty.png'
import {orderStatusMap} from "../../models/order-statuses";
import {selectOrder} from "../../store/orders/actions";

import {
    SimpleCell,
    Div,
    Panel,
    PanelHeader,
} from "@vkontakte/vkui";
import {BackButton, Divider, ForwardButton, HeaderTitle} from '../../components/shared';

const StyledCell = styled(SimpleCell)`
    & h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 120%;
        color: #30363E;
        
        & small:first-child {
            margin-bottom: 14px;
        }
        & small:last-child {
            margin-top: 14px;
        }
        & small {
            display: block;
            font-size: 14px;
            font-weight: 500;
        }
    }
`;



function OrdersHistory({id, setPage, goBack, orders, selectOrder}) {
    React.useEffect(() => {
        gtag.event('view_orders');
    }, [])

    const onClick = (order) => {
        selectOrder(order);
        setPage('home', 'order');
    }

    return (
        <Panel id={id}>
            <PanelHeader left={<BackButton onClick={() => goBack()}/>} className="flex" style={{justifyContent: 'center'}}>
                <HeaderTitle>
                    История заказов
                </HeaderTitle>
            </PanelHeader>
            {orders.length === 0 
            ? <Div style={{minHeight: '80vh', display: 'flex', flexDirection:'column', textAlign: 'center'}}>
                <StyledCell>Сожалеем, но у вас еще нет заказов<br/></StyledCell>
                    <div style={{width: '100%', height: '50vh', background: `url(${empty}) no-repeat center center / contain`, padding: '50% 0 0 0'}}/>
            </Div>
            : <Div style={{minHeight: '80vh', display: 'flex', flexDirection:'column'}}>
                {orders.map((order, key) => 
                    <React.Fragment id={key}>
                        <StyledCell after={<ForwardButton onClick={() => onClick(order)}/>}>
                            <small>
                                <DayJS format="LLL">{order.createdAt}</DayJS>
                            </small>
                            Заказ #{order.code}
                            <small>
                                {orderStatusMap[order.status]}
                            </small>
                        </StyledCell>
                        <Divider/>
                    </React.Fragment>
                )}
            </Div>}
        </Panel>
    );
}

const mapStateToProps = (state) => ({
    orders: state.orders.list,
});

const mapDispatchToProps = {
    setPage,
    goBack,
    selectOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersHistory);
