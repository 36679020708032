import React from 'react';

import {connect} from 'react-redux';
import {goBack, openModal} from "../../store/router/actions";
import {
    Panel,
    Separator,
    PanelHeader,
    List,
    Group,
    Input,
    CellButton,
    Placeholder,
    Div,
    FormItem,
    IconButton,
} from "@vkontakte/vkui";
import * as gtag from '../../utils/gtag';
import {geoSuggests} from '../../http/geo';
import Icon28InfoOutline from '@vkontakte/icons/dist/28/info_outline';
import { selectUserGeoAndAddress } from '../../store/user/actions';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { Icon28CancelOutline, Icon28ChevronRightOutline } from '@vkontakte/icons';
import { BackButton, HeaderTitle } from '../../components/shared';

const awesomeSuggestions = AwesomeDebouncePromise(geoSuggests, 500, {
  leading: true,
});

function SelectLocationPanel({id, goBack, currentAddress, selectUserGeoAndAddress}) {

  const [location, setLocation] = React.useState(currentAddress.name);
  const [results, setResults] = React.useState([]);
  const inputEl = React.useRef(null);

  React.useEffect(() => {
    inputEl.current.focus();
    gtag.event('view-select-location');
    if (currentAddress.name === 'Санкт-Петербург' || currentAddress.name === 'Москва') {
        return;
    }
    changeLocation(currentAddress.name).then();
  }, []);

  async function changeLocation(value) {
    if (!value || value.length < 3) {
      setResults([])
      return
    }
    try {
        setResults(await awesomeSuggestions(value))
    } catch(err) {
        console.log(err)
    }
  }

  const onChangeLocation = async (e) => {
      const value = e.target.value.replace(/\s+/g,' ')
      setLocation(value)
      await changeLocation(value);
  }

  const onClickCell = (item) => {
      setLocation(item.value + ' ')
      setResults([])
      if (item.house) {
          selectUserGeoAndAddress({
              geo: {
                  long: item.lng,
                  lat: item.lat,
                  available: true,
              },
              address: {
                  name: item.value,
                  description: item.city,
                  text: item.name,
                  precision: 'exact',
              }
          })
          gtag.event('select-location');
          goBack()
      }
      inputEl.current.focus()
  } 
  return (
    <Panel id={id}>
      <PanelHeader left={<BackButton onClick={() => goBack()}/>} className="flex" style={{justifyContent: 'center'}}>
          <HeaderTitle>
          Адрес доставки
          </HeaderTitle>
      </PanelHeader>
      <Div>
        <Group>
            <FormItem top="Адрес доставки">
              <Icon28CancelOutline 
                fill="#3f8ae0" 
                width={24} 
                style={{position: 'absolute', right: 32, zIndex: 9999, padding: 8}} 
                onClick={() => {setLocation(''); setResults([])}}/>
              <Input getRef={inputEl} value={location} onChange={onChangeLocation}/> 
            </FormItem>
            <List>
                {(results.length === 0) && 
                  <>
                  <Separator wide/>
                  <Placeholder icon={<Icon28InfoOutline />}>
                    Пожалуйста, введите улицу и номер дома, чтобы мы смогли определить находитесь ли в зоне доставки
                  </Placeholder>
                  </> 
                }
                {results.map((item, key) => (
                    <CellButton
                    key={key}
                    description={item.city}
                    onClick={() => onClickCell(item)}
                    after={item.house && <IconButton icon={<Icon28ChevronRightOutline />}/>}
                >
                    {item.value}
                </CellButton>
                ))}
            </List>
          </Group>
        </Div>
      </Panel>
  );
}

const mapStateToProps = state => ({
  currentAddress: state.user.address,
})

const mapDispatchToProps = {
    goBack,
    openModal,
    selectUserGeoAndAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectLocationPanel);