import { Title } from '@vkontakte/vkui';
import React from 'react';
import styled from 'styled-components';

const AvatarWrapper = styled.div`
    background: ${({viewed}) => !viewed ? `linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)` : '#fff'};
    border-radius: 36px;
    max-width: 70px;
    padding: 2px;

    & .inner-border {
        background: #fff;
        border-radius: 34px;
        padding: 3px;

        & .avatar {
            border-radius: 32px;
            width: 64px;
            background: url(${({picture}) => picture}) no-repeat right bottom / contain;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
            height: 64px;
        }
    }
`
const AvatarTitleWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const AvatarTitle = styled(Title)`
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 64px;
`;

const Avatar = ({picture, onClick, name, viewed}) => (
    <React.Fragment>
    <AvatarWrapper picture={picture} onClick={onClick} viewed={viewed}>
        <div className="inner-border">
            <div className="avatar"/>
        </div>
        
    </AvatarWrapper>
    <AvatarTitleWrapper>
        <AvatarTitle>{name}</AvatarTitle>
    </AvatarTitleWrapper>
    </React.Fragment>
)

export default Avatar;